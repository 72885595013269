import { Injectable } from '@angular/core'
import {
  CptGoogleTagmanagerService,
  EcondaService,
  MatomoIntegrationService
} from '@inside-hub-app/customer-portal-shared'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { LocalStorageService } from './local-storage.service'
import {
  type BrandDTO,
  type VehicleDTOExtended,
  type VehicleDealersDTO,
  VehiclesService
} from './vehicles.service'
import { NGXLogger } from 'ngx-logger'
import { SharedService } from './shared.service'
import { DataService } from './data.service'
import { catchError, EMPTY, forkJoin, map, type Observable } from 'rxjs'
import {
  AppointmentsService,
  type ServiceAppointmentDTO,
  type ServiceEnum,
  type TimeOfDayEnum
} from './appointments.service'
import { TranslateService } from '@ngx-translate/core'
import { type DealerDTO, DealersService } from './dealers.service'
import { type TiresAppointmentDTO, TiresService } from './tires.service'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { type MatStepper } from '@angular/material/stepper'
import { AppointmentsPopupChooseStepComponent } from '../components/revolution/appointments-popup/appointments-popup-choose-step/appointments-popup-choose-step.component'
import { MatDialog } from '@angular/material/dialog'
import { format } from 'date-fns'
export enum ServicesDE {
  tyre_change,
  repairs,
  inspection,
  seasonal_check,
  vacation_check,
  wiper_blades_replacement,
  emission_check,
  ac,
  glass_repair,
  video_check,
  recall,
  other
}

export enum ServicesCH {
  vehicle_inspection_check,
  tyre_change,
  repairs,
  inspection,
  seasonal_check,
  maintenance,
  wiper_blades_replacement,
  other
}

export interface ReifenData {
  vehicle?: VehicleDTOExtended
  dealer?: DealerDTO
  step?: number
  serviceAction?: boolean
}

@Injectable()
export class AppointmentsPopupService {
  allDealers: DealerDTO[]
  dealers: DealerDTO[]
  brandDealers: DealerDTO[]

  dealer: DealerDTO
  chosenDealer: DealerDTO
  public dealerFinal: DealerDTO
  newVehicle: VehicleDTOExtended = {
    brand: '',
    currentMileage: 0,
    dealer: undefined,
    firstRegistration: '',
    fuelType: '',
    licensePlate: '',
    dateCreated: null,
    model: '',
    vin: '',
    efSale: false,
    dealerId: 0,
    dealerCompanyNumber: '',
    dealers: {
      salesDealer: null,
      serviceDealer: null,
      purchasedDealer: null
    }
  }

  public car: VehicleDTOExtended
  appointment: ServiceAppointmentDTO
  vehicles: VehicleDTOExtended[] = []
  public brands: BrandDTO[] = []

  // first *******************
  model = new FormControl<string | null>(null)
  make = new FormControl<string | null>(null)

  licensePlateNormalRegex: string
  licensePlateOfficialRegex: string
  deLicencePlateRegex

  firstFormGroup = new FormGroup({
    cars: new FormControl<string | null>(null)
  })

  licencePlateDE

  licencePlateCanton = new FormControl<string | null>(null)
  licencePlateCH

  checked: boolean
  public showOther: boolean
  link: TiresAppointmentDTO = null
  loadingDealersAndBrands: boolean
  openTireChangeInstantly

  // second *******************
  secondFormGroup = new FormGroup({
    serviceCheckbox: new FormControl<string | null>(null),
    notes: new FormControl<string | null>(null),
    mileage: new FormControl<number | null>(
      null,
      Validators.compose([
        Validators.required,
        Validators.pattern('^([0-9]+$)'),
        Validators.maxLength(7),
        Validators.min(1)
      ])
    ),
    replacement: new FormControl<boolean | null>(null)
  })

  selectedServices: ServiceEnum[] = []
  notes = ''
  currentMileage = 0
  replacement = false

  // third *******************
  thirdFormGroup = new FormGroup({
    date: new FormControl<string | null>(null),
    time: new FormControl<string | null>(null),
    carDealer: new FormControl<DealerDTO | null>(null)
  })

  public showDealerSection: boolean
  date: string
  time: string
  defaultDate
  public minDate: Date
  public showDealerChange: boolean

  // autocomplete
  filteredAllDealers: DealerDTO[] = []
  filteredDealers: DealerDTO[] = []

  // fourth *******************
  disabledSave = false

  // *******************
  reifenData: ReifenData
  country
  loadingCarData: boolean
  public loading: boolean
  showReifenButton = false

  sub = {
    mileageSub: null,
    onUserVehiclesLoadedSub: null,
    dealerSub: null
  }

  selectCarSub = {
    loadCarDataSub: null,
    getLinkSub: null
  }

  constructor (
    public sharedService: SharedService,
    private readonly vehiclesService: VehiclesService,
    private readonly dealersService: DealersService,
    private readonly localStorage: LocalStorageService,
    private readonly tiresService: TiresService,
    private readonly econdaService: EcondaService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly appointmentsService: AppointmentsService,
    public translateService: TranslateService,
    private readonly dataService: DataService,
    private readonly logger: NGXLogger,
    private readonly matomoIntegrationService: MatomoIntegrationService,
    public dialog: MatDialog,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.showDealerChange = this.remoteConfigService.get('useServiceDealer')
    this.licensePlateNormalRegex = this.remoteConfigService.get(
      'regEx.licencePlateRegEx.types.normal.regex'
    )
    this.licensePlateOfficialRegex = this.remoteConfigService.get(
      'regEx.licencePlateRegEx.types.official.regex'
    )
    this.deLicencePlateRegex = new RegExp(
      this.licensePlateNormalRegex + '|' + this.licensePlateOfficialRegex,
      'i'
    )
    this.licencePlateDE = new FormControl<string | null>(
      null,
      Validators.pattern(this.deLicencePlateRegex)
    )
    this.licencePlateCH = new FormControl<string | null>(
      null,
      Validators.compose([
        Validators.pattern(this.licensePlateOfficialRegex),
        Validators.maxLength(6)
      ])
    )
    this.openTireChangeInstantly = this.remoteConfigService.get(
      'openTireChangeInstantly'
    )
  }

  async getDataFromLS (): Promise<void> {
    try {
      this.vehicles = await this.localStorage.getVehicles()
    } catch (error) {
      this.logger.debug(error)
    }
    try {
      this.brands = await this.localStorage.getAllBrands()
    } catch (error) {
      this.logger.debug(error)
    }
    try {
      this.allDealers = await this.localStorage.getAllDealers()
      this.filteredAllDealers = this.sharedService.deepCopy(this.allDealers)
    } catch (error) {
      this.logger.debug(error)
    }

    // there has to be brands and dealers
    if (
      this.brands?.[0] != null &&
      (this.allDealers?.[0] != null || this.dealers?.[0] != null) &&
      this.vehicles != null
    ) {
      this.loading = false
    }
  }

  addDealerToBrandDealers (
    vehicleDealers: VehicleDealersDTO,
    selectedDealer: DealerDTO
  ): void {
    // reset dealers in dropdown to brand dealers
    this.dealers = Object.assign([], this.brandDealers)

    if (vehicleDealers != null) {
      if (vehicleDealers.salesDealer != null) {
        if (
          !this.sharedService.objExistsInArray(
            this.dealers,
            vehicleDealers.salesDealer,
            'id'
          )
        ) {
          this.dealers.push(Object.assign({}, vehicleDealers.salesDealer))
          this.dealers = this.sharedService.sortDealers(this.dealers)
        }
      }

      if (
        Boolean(this.remoteConfigService.get('useServiceDealer')) &&
        vehicleDealers.serviceDealer != null
      ) {
        if (
          !this.sharedService.objExistsInArray(
            this.dealers,
            vehicleDealers.serviceDealer,
            'id'
          )
        ) {
          this.dealers.push(Object.assign({}, vehicleDealers.serviceDealer))
          this.dealers = this.sharedService.sortDealers(this.dealers)
        }
      }
    }

    if (selectedDealer != null) {
      if (
        !this.sharedService.objExistsInArray(this.dealers, selectedDealer, 'id')
      ) {
        this.dealers.push(Object.assign({}, selectedDealer))
        this.dealers = this.sharedService.sortDealers(this.dealers)
      }
    }

    this.filteredDealers = this.sharedService.deepCopy(this.dealers)
  }

  setChosenDealer (): void {
    const k = this.allDealers?.find(
      s => s.id === Number(this.thirdFormGroup.controls.carDealer.value?.id)
    )
    this.chosenDealer = k != null ? k : this.dealer
  }

  addService (service: ServiceEnum): void {
    if (
      this.selectedServices.length !== 0 &&
      this.selectedServices?.find(s => s.toLowerCase() === service.toLowerCase()) !=
        null
    ) {
      this.selectedServices = this.selectedServices.filter(
        obj => obj.toLowerCase() !== service.toLowerCase()
      )
    } else {
      this.selectedServices.push(service)
    }
    this.checkIfReifen()
  }

  checkIfReifen (): void {
    if (
      this.selectedServices.length === 1 &&
      this.selectedServices?.find(s => s?.toUpperCase() === 'TYRE_CHANGE' && this.link) != null
    ) {
      this.showReifenButton = true
    } else {
      this.showReifenButton = false
    }
  }

  loadData (): void {
    this.getBrandsAndAllDealers().subscribe(data => {
      setTimeout(() => {
        this.loading = false
      }, 1000)

      if (!this.showDealerChange) {
        this.dealers = this.sharedService.sortDealers(data.dealers)
        this.filteredDealers = this.sharedService.deepCopy(this.dealers)
      }
      this.allDealers = this.sharedService.sortDealers(data.dealers)
      this.filteredAllDealers = this.sharedService.deepCopy(this.allDealers)
      void this.localStorage.setAllDealers(data.dealers)

      this.brands = data.brands
      void this.localStorage.setAllBrands(data.brands)

      if (this.reifenData?.vehicle != null) {
        this.showDealerSection = false
        this.firstFormGroup.controls.cars.setValue(this.reifenData.vehicle.vin)
        this.car = this.reifenData.vehicle
        void this.selectedCar(this.car)
        if (this.reifenData.step == null) {
          this.addService('TYRE_CHANGE')
        }
      }
    })
  }

  getBrandsAndAllDealers (): Observable<{
    brands: BrandDTO[]
    dealers: DealerDTO[]
  }> {
    return forkJoin(
      this.vehiclesService.getBrands(),
      this.dealersService.getDealers()
    ).pipe(
      map(values => {
        return {
          brands: values[0],
          dealers: values[1]
        }
      })
    )
  }

  async selectedCar (vehicle: VehicleDTOExtended): Promise<void> {
    // unsub previous
    this.sharedService.unsubscribe(this.selectCarSub)
    // reset previous values
    this.brandDealers = []
    this.dealers = []
    this.filteredDealers = []
    this.link = null

    let LSVehicle
    try {
      LSVehicle = await this.localStorage.getVehicleByVin(vehicle.vin)
    } catch (error) {
      this.logger.debug(error)
    }
    const data: Record<string, unknown> = {}
    if (LSVehicle?.relations?.brandDealers != null) {
      data.brandDealers = LSVehicle.relations.brandDealers
    }
    if (LSVehicle?.relations?.tireAppointmentLink != null) {
      data.link = LSVehicle.relations.tireAppointmentLink
    }
    if (
      LSVehicle?.dealers?.salesDealer != null ||
      LSVehicle?.relations?.dealers?.salesDealer != null
    ) {
      data.vehicleDealers =
        LSVehicle?.relations?.dealers?.salesDealer != null
          ? LSVehicle.relations.dealers
          : LSVehicle.dealers
    }
    this.setCarData(vehicle, data)

    this.selectCarSub.loadCarDataSub = this.loadCarData(vehicle).subscribe(
      data => {
        this.setCarData(vehicle, data, true)
      }
    )
  }

  loadCarData (vehicle: VehicleDTOExtended): Observable<{
    brandDealers
    vehicleDealers: VehicleDealersDTO
  }> {
    this.loadingDealersAndBrands = true
    return forkJoin(
      this.getBrandDealers(vehicle),
      this.vehiclesService.getVehicleDealers(vehicle.vin, false, false, false)
    ).pipe(
      map(values => {
        this.loadingDealersAndBrands = false
        return {
          brandDealers: values[0],
          vehicleDealers: values[1]
        }
      }),
      catchError(_err => {
        this.loadingDealersAndBrands = false
        return EMPTY
      })
    )
  }

  async getBrandDealers (vehicle: VehicleDTOExtended): Promise<DealerDTO[]> {
    return await new Promise((resolve, reject) => {
      this.dealersService.getBrandDealers(vehicle.brand).subscribe(
        value => {
          resolve(this.sharedService.sortDealers(value))
        },
        error => {
          reject(error)
        }
      )
    })
  }

  setCarData (vehicle: VehicleDTOExtended, data, updateLS?: boolean): void {
    if (data.vehicleDealers != null) {
      vehicle.dealer = data.vehicleDealers.salesDealer
      vehicle.dealers = data.vehicleDealers
      if (updateLS === true) {
        void this.localStorage.setVehicleDealers(
          vehicle.vin,
          data.vehicleDealers
        )
      }
    }
    if (data.brandDealers != null) {
      this.dealers = data.brandDealers as DealerDTO[]
      this.brandDealers = data.brandDealers as DealerDTO[]
      if (updateLS === true) {
        void this.localStorage.setVehicleBrandDealers(
          vehicle.vin,
          data.brandDealers
        )
      }
    }

    //  check if car dealers are in brand dealers, add them if not
    this.addDealerToBrandDealers(vehicle.dealers, null)

    this.car = vehicle
    if (
      vehicle.dealers != null &&
      this.dealers != null &&
      vehicle.dealers.salesDealer != null &&
      vehicle.dealers.serviceDealer != null &&
      Boolean(this.remoteConfigService.get('useServiceDealer'))
    ) {
      this.car.dealerId = vehicle.dealers.serviceDealer.id
      this.setDealerFinal(this.car?.dealerId, true)
    } else {
      this.setDealerFinal(this.car?.dealer?.id)
      if (this.dealerFinal == null) {
        this.setDealerFinal(this.car.dealerId, true)
      }
    }
    this.dealer = this.hasServiceHours(this.car.dealer) ? this.car.dealer : null
    this.showOther = false
    this.chosenDealer = this.hasServiceHours(this.car.dealer)
      ? this.car.dealer
      : null
    this.thirdFormGroup
      .get('carDealer')
      .setValue(
        this.hasServiceHours(this.dealerFinal)
          ? this.dealerFinal
          : this.hasServiceHours(this.car.dealer)
            ? this.car.dealer
            : null
      )
    if (vehicle.createdByDataSource === 'customer_portal') {
      this.loadingCarData = false
      this.link = null
      this.showReifenButton = false
    } else {
      if (data.link != null) {
        this.setLink(data.link)
      }
      this.selectCarSub.getLinkSub = this.getLink(this.car.vin).subscribe(
        data => {
          this.setLink(data)
          if (updateLS === true) {
            void this.localStorage.setTiresAppintment(vehicle.vin, data.link)
          }
        },
        err => {
          this.logger.debug(err)
          this.loadingCarData = false
          this.showReifenButton = false
        }
      )
    }
    this.showDealerSection = true
  }

  hasServiceHours (dealer): boolean {
    return dealer?.openingHours?.service?.length > 0
  }

  setDealerFinal (dealerId, setFirst?: boolean): void {
    this.dealerFinal = null
    if (dealerId != null && this.dealers?.[0] != null) {
      this.dealerFinal = this.dealers?.find(s => s.id === dealerId)
    }

    if (this.dealerFinal == null && setFirst === true) {
      this.dealerFinal = this.dealers?.[0]
    }
  }

  setLink (data): void {
    this.loadingCarData = false
    this.link = data?.link != null ? data.link : null
    this.checkIfReifen()
  }

  getLink (vin: string): Observable<{
    link: TiresAppointmentDTO
  }> {
    return this.tiresService.tireAppointmentLink(vin).pipe(
      map(values => {
        return {
          link: values
        }
      })
    )
  }

  decideNextStep (stepper: MatStepper): void {
    const dialogRef = this.dialog.open(AppointmentsPopupChooseStepComponent, {
      panelClass: ['mat-dialog-cpt', 'choose-step'],
      data: {
        vehicle: this.car
      }
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'CPT') {
        stepper.next()
      }
      if (result === 'reifen') {
        this.openReifen()
      }
    })
  }

  openReifen (): void {
    window.open(this.link != null ? this.link.url : '', '_blank')
    this.sendGTMData(2, true)
  }

  sendGTMData (step: number, reifen?: boolean): void {
    let data
    if (reifen === true) {
      data = {
        step,
        delivery: 'Delivery',
        reifenManager: true
      }
    } else {
      switch (step) {
        case 1:
          data = {
            step
          }
          this.econdaService.send({
            content: 'CTA',
            Target: ['New appointment - step1', '', 1, 'd']
          })
          this.matomoIntegrationService.trackEvent(
            'CTA',
            'New appointment (step 1)',
            this.matomoIntegrationService.formatVehicleData(this.car != null ? this.car : this.reifenData?.vehicle)
          )
          break
        case 2:
          data = {
            step,
            delivery: 'Delivery',
            brand: this.car?.brand != null ? this.car.brand : ' - ',
            model: this.car?.model != null ? this.car.model : ' - '
          }
          this.econdaService.send({
            content: 'CTA',
            Target: ['New appointment - step2', JSON.stringify(data), 1, 'd']
          })
          this.matomoIntegrationService.trackEvent(
            'CTA',
            'New appointment (step 2)',
            this.matomoIntegrationService.formatVehicleData(this.car != null ? this.car : this.reifenData?.vehicle)
          )
          break
        case 3:
          data = {
            step,
            delivery: 'Delivery',
            selectedServices: this.selectedServices,
            replacement: this.replacement == null ? false : this.replacement
          }
          this.econdaService.send({
            content: 'CTA',
            Target: ['New appointment - step 3', JSON.stringify(data), 1, 'd']
          })
          this.matomoIntegrationService.trackEvent(
            'CTA',
            'New appointment (step 3)',
            this.matomoIntegrationService.formatVehicleData(this.car != null ? this.car : this.reifenData?.vehicle)
          )
          break
        case 4:
        case 5:
          data = {
            step,
            delivery: 'Delivery',
            dealer: this.chosenDealer,
            date: this.date,
            selectedServices: this.selectedServices,
            brand: this.car?.brand != null ? this.car.brand : ' - ',
            model: this.car?.model != null ? this.car.model : ' - ',
            replacement: this.replacement == null ? false : this.replacement
          }
          this.econdaService.send({
            content: 'CTA',
            Target: ['New appointment', JSON.stringify(data), 1, 'd']
          })
          this.matomoIntegrationService.trackEvent(
            'CTA',
            'New appointment (' + 'step ' + step + ')',
            this.matomoIntegrationService.formatVehicleData(this.car)
          )
          break
      }
    }
    this.cptGtmService.sendServiceAppointmentCheckoutData(data)
  }

  setAllData (): void {
    this.notes = this.secondFormGroup.controls.notes.value
    this.currentMileage = this.secondFormGroup.controls.mileage.value
    this.replacement = this.secondFormGroup.controls.replacement.value
    this.date = this.thirdFormGroup.controls.date.value
    this.time = this.thirdFormGroup.controls.time.value
    this.setChosenDealer()
    if (this.showOther) {
      this.newVehicle.licensePlate =
        this.licencePlateCH.value != null && this.licencePlateCanton != null
          ? String(this.licencePlateCanton.value) +
            String(this.licencePlateCH.value)
          : this.licencePlateDE.value
    }
  }

  checkSelected (): void {
    if (this.showOther && this.make.value != null && this.model.value != null) {
      this.newVehicle.model = this.model.value
      this.newVehicle.brand = this.make.value

      this.car = this.newVehicle
    }
  }

  createAppointment (dialogRef): void {
    this.disabledSave = true
    const parts = this.time.split('.')
    const timeValue = parts[parts.length - 1]
    this.appointment = {
      currentMileage:
        this.currentMileage != null
          ? this.currentMileage
          : this.car.manualMileage != null
            ? this.car.manualMileage
            : this.car.currentMileage,
      notes: this.notes,
      preferredDate: format(new Date(this.date), 'yyyy-MM-dd'),
      preferredTimeOfDay: timeValue.toUpperCase() as TimeOfDayEnum,
      requestReplacement: this.replacement,
      services: this.selectedServices,
      vin: this.car.vin != null ? this.car.vin : null,
      dealerId: this.chosenDealer.id,
      brand: this.car?.brand != null ? this.car.brand : ' - ',
      model: this.car?.model != null ? this.car.model : ' - ',
      licensePlate:
        this.car.licensePlate != null
          ? this.car.licensePlate
          : this.newVehicle != null
            ? this.newVehicle.licensePlate
            : ' - '
    }

    this.appointmentsService
      .postAppointment(this.appointment, this.sharedService.currentLanguage())
      .subscribe(
        data => {
          this.dataService.newAppointmentAdded()
          this.logger.debug('Request is successful ', data)
          this.sharedService.openConfirmationPopup(
            'customerPortal.customer-portal.appointments.sent.thankyou',
            'customerPortal.customer-portal.appointments.sent.message'
          )
          this.sendGTMData(5)
          dialogRef.close()
        },
        error => {
          this.sharedService.openConfirmationPopup(
            'shared.error',
            'shared.general-error-message'
          )
          this.sendGTMData(5)
          this.logger.error('Error', error)
          dialogRef.close()
        }
      )
  }

  otherVehicleFormIsInvalid (): boolean {
    if (
      this.showOther &&
      (this.make.invalid ||
        this.model.invalid ||
        this.licencePlateDE.invalid ||
        this.licencePlateCH.invalid)
    ) {
      return true
    } else {
      return false
    }
  }
}
