<div class="cpt-modal relative mat-dialog-actions-pb-20">
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    <T
      str="Update e-mail"
      key="customerPortal.customer-portal.details.change.email"
      tags="customer-portal, 3.1"
    ></T>
  </h2>
  <mat-dialog-content class="pt-2.5 pb-5 px-0">
    <div class="mb-5">
      <T
        str="Please note if you update your email address, the new email address will be used as your login username. You must first confirm the new email address."
        key="customerPortal.customer-portal.details.change.email.text"
        tags="customer-portal, 3.1"
      ></T>
    </div>

    <form class="form" [formGroup]="emailForm">
      <div class="grid gap-2">
        <mat-form-field appearance="legacy" class="newEmail">
          <mat-label>
            <T
              str="Email Address"
              key="customerPortal.customer-portal.details.login.email"
              tags="customer-portal, 3.1"
            ></T
          ></mat-label>
          <!-- prettier-ignore -->
          <input
            matInput
            placeholder="{{ 'Email Address' | translate:{ _key: 'customerPortal.customer-portal.details.login.email', _tags: 'customer-portal, 3.1'} }}"
            required
            formControlName="email"
          />
          <mat-error *ngIf="emailForm.get('email').errors">
            {{
              transifexService.getErrorTranslation(
                emailForm.get('email'),
                'customerPortal.customer-portal.details.error.',
                {
                  errKey: {
                    pattern: 'enter-valid-email',
                    email: 'enter-valid-email',
                    maxlength: 'maximum-length-exceeded'
                  }
                }
              )
            }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="legacy">
          <mat-label>
            <T
              str="Repeat email address"
              key="customerPortal.customer-portal.details.login.repeat-email"
              tags="customer-portal, 3.1"
            ></T
          ></mat-label>
          <!-- prettier-ignore -->
          <input
            matInput
            placeholder="{{ 'Repeat email address' | translate:{ _key: 'customerPortal.customer-portal.details.login.repeat-email', _tags: 'customer-portal, 3.1'} }}"
            required
            formControlName="repeatEmail"
            [errorStateMatcher]="matcher"
          />
          <mat-error *ngIf="emailForm.hasError('notsame')">
            <T
              str="Email addresses do not match"
              key="customerPortal.customer-portal.details.error.email.notsame"
              tags="customer-portal, 3.1"
            ></T>
          </mat-error>
          <mat-error
            *ngIf="
              emailForm.controls.repeatEmail.invalid &&
              !emailForm.hasError('notsame')
            "
          >
            {{
              transifexService.getErrorTranslation(
                emailForm.controls.repeatEmail,
                'customerPortal.customer-portal.details.error.',
                {
                  errKey: {
                    pattern: 'enter-valid-email',
                    email: 'enter-valid-email',
                    maxlength: 'maximum-length-exceeded'
                  }
                }
              )
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="dialog-close-button" [mat-dialog-close]="false">
      <mat-icon svgIcon="close"></mat-icon>
    </button>

    <button
      [disabled]="saveDisabled()"
      mat-button
      mat-flat-button
      color="secondary"
      class="revolution btn-dialog"
      (click)="updateEmail()"
      cdkFocusInitial
    >
      <mat-spinner *ngIf="saving" diameter="20"></mat-spinner>
      {{ 'save' | sharedTranslate }}
    </button>
  </mat-dialog-actions>
</div>
