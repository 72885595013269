import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatMenuTrigger } from '@angular/material/menu'
import { User } from '@inside-hub-app/customer-portal-b2c-client'
import { CptGoogleTagmanagerService } from '@inside-hub-app/customer-portal-shared'
import {
  CustomerPortalConfig,
  Link,
  RevolutionLink
} from '@inside-hub-app/customer-portal-config'
import { KeycloakService } from '@emilfreydigital/keycloak-angular'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { TranslateService } from '@ngx-translate/core'
import { NGXLogger } from 'ngx-logger'
import { DataService } from '../../../../services/data.service'
import { LanguageService } from '../../../../services/language.service'
import { LocalStorageService } from '../../../../services/local-storage.service'
import { SharedService } from '../../../../services/shared.service'
import { VehicleDTOExtended } from '../../../../services/vehicles.service'
import { LanguageChangeMenuComponent } from '../language-change-menu/language-change-menu.component'
import { MobileMenuComponent } from '../mobile-menu/mobile-menu.component'
import { UserMenuComponent } from '../user-menu/user-menu.component'

@Component({
  selector: 'customer-portal-app-navbar-right',
  templateUrl: './navbar-right.component.html'
})
export class NavbarRightComponent implements OnInit {
  user: User
  vehicles: VehicleDTOExtended[] = []
  vin: string
  loggedIn: boolean
  loading: boolean
  languages: string[]
  initials: string
  primaryLang: string
  show: boolean
  @Output() changeShow = new EventEmitter<boolean>()

  currentLanguage: string

  country
  hasMarketplace

  baseUrl: string
  @Input() header
  showMenu2 = false
  openedSubMenu = null

  @ViewChild(UserMenuComponent, { static: true })
  userMenuComponentMenu: UserMenuComponent

  @ViewChild(MobileMenuComponent, { static: true })
  mobileMenuComponentMenu: MobileMenuComponent

  @ViewChild(LanguageChangeMenuComponent, { static: true })
  languageChangeMenuComponent: LanguageChangeMenuComponent

  @ViewChild('languageMenuTrigger') languageMenuTrigger: MatMenuTrigger

  isMobilePwa
  efWebsiteUrl: string
  countryCode: string
  constructor(
    private readonly dataService: DataService,
    private readonly dialog: MatDialog,
    private readonly languageService: LanguageService,
    private readonly keycloak: KeycloakService,
    private readonly logger: NGXLogger,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly translate: TranslateService,
    public sharedService: SharedService,
    private readonly localStorageService: LocalStorageService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.isMobilePwa = this.sharedService.isMobilePwa()
    this.country = this.remoteConfigService.get('country.code')
    this.hasMarketplace = this.remoteConfigService.get('hasMarketPlace')
    this.efWebsiteUrl = this.remoteConfigService.get('efWebsiteUrl')
    this.countryCode = this.remoteConfigService.get('country.code')
  }

  onShow(): void {
    this.show = !this.show
    if (this.show) {
      document.body.classList.add('overflow-hidden', 'md:overflow-auto')
    } else {
      document.body.classList.remove('overflow-hidden', 'md:overflow-auto')
    }
    this.changeShow.emit(this.show)
  }

  getNavbarLink(link: RevolutionLink): string {
    return this.sharedService.getLink(link)
  }

  goToLink(url: string): void {
    window.open(url, '_blank')
  }

  ngOnInit(): void {
    this.currentLanguage = this.translate.currentLang
    this.translate.onLangChange.subscribe(val => {
      this.currentLanguage = val.lang
    })
    void this.keycloak.isLoggedIn().then(loggedIn => (this.loggedIn = loggedIn))

    this.dataService.onUserLoaded.subscribe(user => {
      this.user = user
      if (user != null) {
        this.loggedIn = true
      }
    })

    this.dataService.onUserVehiclesLoaded.subscribe(
      data => {
        this.vehicles = data
        if (this.vin == null && data?.[0] != null) {
          this.vin = data[0].vin
        }
      },
      err => {
        if (err.status === 404) {
          this.vehicles = []
        }
      }
    )
    this.dataService.onVehicleVinChange.subscribe(vin => {
      this.vin = vin
    })

    this.baseUrl =
      this.efWebsiteUrl +
      (this.countryCode === 'ch' ? '/' + this.translate.currentLang : '')
  }

  sendGTMNavigationData(link: Link, label?: string, eventName?: string): void {
    const evtName = eventName != null ? eventName : 'Top Icons'
    if (link != null) {
      this.cptGtmService.sendNavigationLinkData(link.eventLabel, evtName)
    } else {
      this.cptGtmService.sendNavigationLinkData(null, evtName, label)
    }
  }

  sendGTMNavigationDataBurger(label: string, linkType?: string): void {
    this.cptGtmService.sendNavigationLinkData(
      null,
      'Main Navigation',
      label,
      linkType
    )
  }

  load(): void {
    this.languageService.getLanguages().subscribe(value => {
      this.languages = value
    })
    this.loading = true
    this.dataService.onUserLoaded.subscribe(user => {
      this.user = null
      if (user != null) {
        this.user = user
        this.loading = false
        this.primaryLang =
          this.user.language != null
            ? this.user.language.find(s => s.primary).language
            : 'de'
        this.localStorageService.setLang(this.primaryLang.toLowerCase())
        this.dataService.initialLanguageSetup(this.primaryLang)
      }
    })
  }

  showMenu(i, ev): void {
    if (this.openedSubMenu === i) {
      this.openedSubMenu = null
    } else {
      this.openedSubMenu = i
    }

    this.sharedService.preventEventPropagation(ev)
  }
}
