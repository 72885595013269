import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { HashingService } from './hashing.service'
import { TokenService } from './token.service'
import { hr as cpt } from 'efd-cpt-backend-interfaces-ts'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'

export type TiresStockDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.TiresStockDTO
export type TyreDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.vehicle.dto.TyreDTO
export type TiresAppointmentDTO =
  cpt.emilfreydigital.customer_portal_backend.rest.appointment.dto.TiresAppointmentDTO

export interface ITiresService {
  getTires: (vin: string) => Observable<TiresStockDTO>
}

@Injectable({
  providedIn: 'root'
})
export class TiresService implements ITiresService {
  private readonly apiUrl

  constructor(
    private readonly http: HttpClient,
    private readonly hashingService: HashingService,
    private readonly tokenService: TokenService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.apiUrl = this.remoteConfigService.get('backend.url')
  }

  getTires(vin: string): Observable<TiresStockDTO> {
    const token = this.tokenService.getToken()
    return this.http.get<TiresStockDTO>(
      this.apiUrl + '/vehicle/tires-stock/' + vin,
      {
        params: { c: this.hashingService.hashCode(token.cp_id) }
      }
    )
  }

  tireAppointmentLink(vin: string): Observable<TiresAppointmentDTO> {
    const token = this.tokenService.getToken()
    return this.http.get<TiresAppointmentDTO>(
      this.apiUrl + '/appointment/tires/' + vin,
      {
        params: { c: this.hashingService.hashCode(token.cp_id) }
      }
    )
  }
}
