import { Component, Inject } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { type VehicleDTOExtended } from '../../../../services/vehicles.service'
import { SharedService } from '../../../../services/shared.service'
import { type DealerDTO } from '../../../../services/dealers.service'

interface AppointmentsPopupChooseStepComponentData {
  vehicle: VehicleDTOExtended
}
@Component({
  selector: 'customer-portal-app-appointments-popup-choose-step',
  templateUrl: './appointments-popup-choose-step.component.html'
})
export class AppointmentsPopupChooseStepComponent {
  public form = new FormGroup({
    type: new FormControl<'CPT' | 'reifen' | null>(null)
  })

  serviceDealer: DealerDTO
  formattedDealerName: string

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: AppointmentsPopupChooseStepComponentData,
    private readonly dialogRef: MatDialogRef<AppointmentsPopupChooseStepComponent>,
    private readonly util: SharedService
  ) {
    this.serviceDealer = data?.vehicle?.dealers?.serviceDealer
    this.formattedDealerName = this.util.formatDealerName(this.serviceDealer)
  }

  select (): void {
    this.dialogRef.close(this.form.get('type')?.value)
  }
}
