import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { User } from '@inside-hub-app/customer-portal-b2c-client'
import { SharedService } from '../../../../services/shared.service'

import { CptGoogleTagmanagerService } from '@inside-hub-app/customer-portal-shared'
import { AppointmentsService } from '../../../../services/appointments.service'
import {
  WorkingHoursResponse,
  WorkingHoursService
} from '../../../../services/working-hours.service'
import { AppointmentsPopupComponent } from '../../../revolution/appointments-popup/appointments-popup.component'
import {
  DayTimeDTO,
  DealerDTO,
  WorkingDays
} from '../../../../services/dealers.service'

@Component({
  selector: 'customer-portal-app-revolution-dealer-info',
  templateUrl: './revolution-dealer-info.component.html'
})
export class RevolutionDealerInfoComponent implements OnInit {
  @Input() dealer: DealerDTO
  @Input() type: string
  @Input() liveChatAvailable
  @Output() openChatWindow = new EventEmitter()

  service: DayTimeDTO[]
  sales: DayTimeDTO[]

  workingDaysSales: WorkingDays[] = []
  workingDaysService: WorkingDays[] = []

  workingDaysSalesGrouped = {}
  workingDaysServiceGrouped = {}

  public user: User
  public day: string

  open = false
  public currentDate: Date
  workingHours: WorkingHoursResponse
  constructor(
    private readonly workingHoursService: WorkingHoursService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly appointmentsService: AppointmentsService,
    public readonly sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.currentDate = new Date()
    this.service =
      this.dealer.openingHours?.service != null
        ? this.dealer.openingHours.service
        : []
    this.sales =
      this.dealer.openingHours?.sales != null
        ? this.dealer.openingHours.sales
        : []
    if (this.type === 'sales') {
      if (this.sales.length > 0) {
        this.workingDaysSalesGrouped = this.workingHoursService.showHoursSales(
          this.sales,
          this.workingDaysSales
        )
        this.workingHours = this.workingHoursService.calculateIfWorking(
          this.sales,
          this.currentDate,
          'sales'
        )
      } else {
        this.workingHours = this.workingHoursService.calculateIfWorking(
          this.service,
          this.currentDate,
          'service'
        )
      }
    } else {
      if (this.service.length > 0) {
        this.workingDaysServiceGrouped =
          this.workingHoursService.showHoursService(
            this.service,
            this.workingDaysService
          )
        this.workingHours = this.workingHoursService.calculateIfWorking(
          this.service,
          this.currentDate,
          'service'
        )
      } else {
        this.workingHours = this.workingHoursService.calculateIfWorking(
          this.sales,
          this.currentDate,
          'sales'
        )
      }
    }

    if (this.workingHours == null) {
      this.workingHours = { open: false }
    }
  }

  sendOutboundLinkData(outboundLinkType: string, dealer: DealerDTO): void {
    this.cptGtmService.sendOutboundData(outboundLinkType, dealer)
  }

  sendContentModulesCTAData(contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'CTA',
      'Click',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      'CTA',
      this.sharedService.translateLink(contentModuleLabel) ?? ''
    )
  }

  sendLiveChatOpenedGtmData(): void {
    this.cptGtmService.sendLiveChatOpenedGtmData()
  }

  openPhoneClient(event: Event): void {
    event.stopPropagation()
    const phone = this.dealer.telephone.toLowerCase()
    const link = 'tel:' + phone
    window.open(link, '_blank')
  }

  openEmailClient(event: Event): void {
    event.stopPropagation()
    const mail = this.dealer.email.toLowerCase()
    const link = 'mailto:' + mail
    window.open(link, '_blank')
  }

  newAppointment(): void {
    const data = { dealer: this.dealer }
    this.appointmentsService.newAppointment(AppointmentsPopupComponent, data)
  }
}
