<div data-cy="appointments-popup-choose-step"
  class="cpt-modal ConfirmationPopupComponent-dialog relative dialog-content-p-0 mat-dialog-actions-pb-20">
  <h2 class="font-canelablack-primary text-4xl" mat-dialog-title>
    <T str="Choose next step" key="customerPortal.customer-portal.appointments.choose-next-step"
      tags="customer-portal, 3.10"></T>
  </h2>

  <mat-dialog-content class="mat-typography AppointmentsPopupChooseStepComponent">
    <form [formGroup]="form">
      <mat-radio-group class="flex flex-col gap-5 items-center" formControlName="type" required>
        <div (click)="form?.patchValue({ type: 'reifen' })"
          class="w-full border rounded-lg p-4 flex flex-col gap-4 cursor-pointer" [ngClass]="{
            'bg-mat-ef-revolution-supergrey-500':
              form?.get('type')?.value === 'reifen'
          }">
          <mat-radio-button #reifen value="reifen" [disableRipple]="true"
            [checked]="form?.get('type')?.value === 'reifen'">
            <div class="font-efSans-primary font-bold text-sm whitespace-normal pl-2">
              <T str="Use dealership from service manager"
                key="customerPortal.customer-portal.appointments.service-manager-dealership.title"
                tags="customer-portal, 3.10"></T>
            </div>
          </mat-radio-button>
          <div class="font-efSans-primary font-normal text-sm whitespace-normal pl-9 flex-1">
            <T str="Dealership for your new appointment will be chosen automatically from service manager"
              key="customerPortal.customer-portal.appointments.service-manager-dealership.text"
              tags="customer-portal, 3.10"></T>
          </div>
          <ng-container *ngIf="serviceDealer?.id">
            <mat-divider class="m-0"></mat-divider>
            <div>
              <div class="font-efSans-primary font-normal text-sm whitespace-normal pl-9 flex-1">
                <div class="font-bold">
                  <T str="Selected service dealer:"
                    key="customerPortal.customer-portal.appointments.service-manager-dealership.selected"
                    tags="customer-portal, 3.11"></T>
                </div>
                <div>{{ formattedDealerName }}</div>
              </div>
            </div>
          </ng-container>
        </div>
        <div (click)="form?.patchValue({ type: 'CPT' })"
          class="w-full border rounded-lg p-4 flex flex-col gap-4 cursor-pointer" [ngClass]="{
            'bg-mat-ef-revolution-supergrey-500':
              form?.get('type')?.value === 'CPT'
          }">
          <mat-radio-button #CPT value="CPT" [disableRipple]="true" [checked]="form?.get('type')?.value === 'CPT'">
            <div class="font-efSans-primary font-bold text-sm whitespace-normal pl-2">
              <T str="Choose any dealership"
                key="customerPortal.customer-portal.appointments.choose-any-dealership.title"
                tags="customer-portal, 3.10"></T>
            </div>
          </mat-radio-button>
          <div class="font-efSans-primary font-normal text-sm whitespace-normal pl-9 flex-1">
            <T str="Manually choose dealership"
              key="customerPortal.customer-portal.appointments.choose-any-dealership.text2"
              tags="customer-portal, 3.10"></T>
          </div>
        </div>
      </mat-radio-group>
    </form>
  </mat-dialog-content>

  <button class="dialog-close-button" [mat-dialog-close]="false">
    <mat-icon svgIcon="close"></mat-icon>
  </button>

  <mat-dialog-actions class="mt-4">
    <button data-cy="add-new-appointment-submit" class="next float-right revolution w-full md:w-auto" mat-flat-button
      color="secondary" mat-button [disabled]="!form?.valid || !form?.get('type')?.value" (click)="select()">
      {{ 'next' | sharedTranslate }}
    </button>
  </mat-dialog-actions>
</div>