<div
  class="flex gap-6"
  [ngClass]="{ 'flex-col': hasRecalls, 'flex-col-reverse': !hasRecalls }"
>
  <div *ngIf="featuresRecalls && enableEfitData" class="vehicle-boxes-rounded">
    <p
      *ngIf="!loadingRecalls"
      class="font-efSans text-xl font-bold mb-2 flex items-center justify-between"
    >
      <T
        str="Service promotion"
        key="customerPortal.customer-portal.top-bar.service-action.title"
        tags="customer-portal, 3.1"
      ></T>

      <mat-icon
        svgIcon="error"
        class="text-mat-ef-red-secondary-500 min-h-6 min-w-6"
        *ngIf="hasRecalls"
      ></mat-icon>
    </p>
    <div *ngIf="loadingRecalls">
      <ngx-skeleton-loader
        appearance="line"
        animation="progress"
      ></ngx-skeleton-loader>
    </div>
    <div class="font-efSans text-sm">
      <ng-container *ngIf="!loadingRecalls">
        <div *ngIf="recallResponse">
          <ng-container
            *ngTemplateOutlet="
              sharedService.template('transifexTranslation');
              context: {
                str: recallResponse.status.toLowerCase(),
                prefix: 'customerPortal.customer-portal.service-action.'
              }
            "
          ></ng-container>
        </div>
        <div *ngIf="!recallResponse">
          <T
            str="You have no open service promotions."
            key="customerPortal.customer-portal.service-action.none"
            tags="customer-portal, 3.1"
          ></T>
        </div>
      </ng-container>

      <div
        *ngIf="loadingRecalls"
        class="w-full py-10 flex justify-center items-center"
      >
        <customer-portal-ef-spinner
          [color]="'mat-ef-revolution-grey-two-500'"
          [opacity]="0.6"
          [diameter]="50"
        >
        </customer-portal-ef-spinner>
      </div>
    </div>

    <button
      *ngIf="hasRecalls"
      (click)="
        newAppointment(true);
        sendContentModulesCTAData(
          'customerPortal.customer-portal.book-appointment'
        )
      "
      class="revolution medium-button mt-6"
      mat-button
      mat-stroked-button
      color="secondary"
    >
      <T
        str="New appointment"
        key="customerPortal.customer-portal.book-appointment"
        tags="customer-portal, 3.1"
      ></T>
    </button>
  </div>
  <div class="vehicle-boxes-rounded">
    <p
      *ngIf="
        !loadingServiceHistory &&
        serviceHistories.length > 0 &&
        months &&
        serviceKilometers
      "
      class="font-efSans text-xl font-bold mb-2"
    >
      <T
        str="Last service"
        key="customerPortal.customer-portal.last-service"
        tags="customer-portal, 3.1"
      ></T>
    </p>

    <p
      *ngIf="
        !loadingServiceHistory &&
        !(serviceHistories.length > 0 && months && serviceKilometers)
      "
      class="font-efSans text-xl font-bold mb-2"
    >
      {{ 'service' | sharedTranslate }}
    </p>

    <div *ngIf="loadingServiceHistory">
      <ngx-skeleton-loader
        appearance="line"
        animation="progress"
      ></ngx-skeleton-loader>
    </div>

    <div class="font-efSans text-sm">
      <ng-container *ngIf="!loadingServiceHistory">
        <div *ngIf="serviceHistories.length > 0 && months && serviceKilometers">
          <ng-container
            *ngTemplateOutlet="
              sharedService.template('transifexTranslation');
              context: {
                key: 'customerPortal.customer-portal.service-visit',
                params: {
                  months: months,
                  kilometers: serviceKilometers | number: '1.0-0':currencyLocale
                }
              }
            "
          ></ng-container>
        </div>

        <div
          *ngIf="!(serviceHistories.length > 0 && months && serviceKilometers)"
        >
          <T
            str="There is no service for this vehicle."
            key="customerPortal.customer-portal.there-is-no-service"
            tags="customer-portal, 3.1"
          ></T>
        </div>
      </ng-container>

      <div
        *ngIf="loadingServiceHistory"
        class="w-full py-10 flex justify-center items-center"
      >
        <customer-portal-ef-spinner
          [color]="'mat-ef-revolution-grey-two-500'"
          [opacity]="0.6"
          [diameter]="50"
        >
        </customer-portal-ef-spinner>
      </div>
    </div>

    <button
      data-cy="vehicle-last-action-service-newAppointment-button"
      (click)="
        link && openTireChangeInstantly ? decideNextStep() : newAppointment();
        sendContentModulesCTAData(
          'customerPortal.customer-portal.book-appointment'
        )
      "
      *ngIf="!loadingServiceHistory"
      class="revolution medium-button mt-6"
      mat-button
      mat-stroked-button
      color="secondary"
    >
      <T
        str="New appointment"
        key="customerPortal.customer-portal.book-appointment"
        tags="customer-portal, 3.1"
      ></T>
    </button>
  </div>
</div>
