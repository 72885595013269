import { animate, state, style, transition, trigger } from '@angular/animations'
import { Component, Input, type OnChanges, type OnDestroy, type OnInit, type SimpleChanges, ViewChild } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table'
import { ActivatedRoute } from '@angular/router'
import {
  CptGoogleTagmanagerService, MatomoIntegrationService,
  ResizeService, type SCREEN_SIZE
} from '@inside-hub-app/customer-portal-shared'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { TranslationService } from '@emilfreydigital/transifex-angular'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NgxGlideComponent } from 'ngx-glide'
import { NGXLogger } from 'ngx-logger'
import { DataService } from '../../../services/data.service'
import { DocumentsService } from '../../../services/documents.service'
import { LocalStorageService } from '../../../services/local-storage.service'
import { SharedService } from '../../../services/shared.service'
import {
  type BasicDocumentDTOExtended,
  type DocumentType,
  VehicleDocumentsService
} from '../../../services/vehicle-documents.service'
import { VehicleDTOExtended } from '../../../services/vehicles.service'
import { type cptMenuAction } from '../../basic/cpt-menu/cpt-menu.component'
import { RevolutionVehicleDocumentDeleteConfirmationPopupComponent } from './vehicle-document-delete-confirmation-popup/vehicle-document-delete-confirmation-popup.component'
import { RevolutionVehicleDocumentEditPopupComponent } from './vehicle-document-edit-popup/vehicle-document-edit-popup.component'
import { RevolutionVehicleDocumentUploadPopupComponent } from './vehicle-document-upload-popup/vehicle-document-upload-popup.component'
import { combineLatest } from 'rxjs'

@Component({
  selector: 'customer-portal-app-revolution-vehicle-documents',
  templateUrl: './vehicle-documents.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class RevolutionVehicleDocumentsComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('ngxGlide') ngxGlide!: NgxGlideComponent
  displayedColumns: string[] = [
    'icon',
    'name',
    'format',
    'lastModified',
    'action'
  ]

  dataSource = new MatTableDataSource([])

  @Input() vehicle: VehicleDTOExtended

  skipViewUpdateForVin // after file is read dont reload list
  myDocuments: BasicDocumentDTOExtended[] = []
  documents: BasicDocumentDTOExtended[] = []
  invoices: BasicDocumentDTOExtended[] = []

  allVehicleDocuments: BasicDocumentDTOExtended[] = []
  vehicleDocuments: BasicDocumentDTOExtended[] = []
  vehicleImages: BasicDocumentDTOExtended[] = []
  documentTypes: DocumentType[]
  selectedDocumentType: string[] = []
  showMenu = false
  showDocumentMenu = false
  public serviceCodeTranslations = {}

  public screenSize: SCREEN_SIZE
  @ViewChild('salesPaginator') salesPaginator: MatPaginator
  @ViewChild('paginator') paginator: MatPaginator

  spinnerSize = 100
  pageSize = 10
  allFilteredDocumentsLength: number
  public country

  documentSelectFormGroup = new FormGroup({
    documentType: new FormControl<DocumentType[] | []>([])
  })

  sub = {
    onVehicleDocumentsLoaded: null,
    onVehicleDetailsLoaded: null,
    onResize: null,
    download: null,
    combineDocumentsInvoices: null,
    combineLoading: null,
    onMyDocumentsLoaded: null,
    onUnreadVehicleDocuments: null
  }

  loading: boolean
  userVehicles: VehicleDTOExtended[]
  loadingDocuments: boolean
  vehicleDetailed: VehicleDTOExtended
  showPaginator = true
  downloadStarted: boolean

  documentIndex
  showSalesInvoiceDownloadButton: boolean
  showServiceInvoiceDownloadButton: boolean
  dateFormat: string

  unread
  constructor (
    private readonly dialog: MatDialog,
    private readonly vehicleDocumentsService: VehicleDocumentsService,
    private readonly documentsService: DocumentsService,
    private readonly route: ActivatedRoute,
    private readonly dataService: DataService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    public sharedService: SharedService,
    private readonly logger: NGXLogger,
    public resizeService: ResizeService,
    private readonly localStorage: LocalStorageService,
    private readonly transifexTranslationsService: TranslationService,
    private readonly matomoIntegrationService: MatomoIntegrationService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.country = this.remoteConfigService.get('country.code')
    this.selectedDocumentType = null
    this.showSalesInvoiceDownloadButton = this.remoteConfigService.get('documents.showSalesInvoiceDownloadButton')
    this.showServiceInvoiceDownloadButton = this.remoteConfigService.get('documents.showServiceInvoiceDownloadButton')
    this.dateFormat = this.remoteConfigService.get('dateFormat.long')
  }

  compareWith (type1: DocumentType, type2: DocumentType): boolean {
    return type1 != null && type2 != null && type1?.key === type2?.key
  }

  hideInvoiceNumber (h: BasicDocumentDTOExtended): boolean {
    return (
      h?.code?.startsWith('F') === true || h?.code?.startsWith('G') === true
    )
  }

  sendContentModulesData (expanded: boolean, contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'Accordion',
      expanded ? 'Open' : 'Close',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      `Accordion|${this.sharedService.translateLink(contentModuleLabel) ?? ''}`,
      expanded ? 'Open' : 'Close'
    )
  }

  sendContentModulesDataWithoutTranslate (
    expanded: boolean,
    contentModuleLabel: string
  ): void {
    this.cptGtmService.sendContentModulesData(
      'Accordion',
      expanded ? 'Open' : 'Close',
      contentModuleLabel ?? '',
      `Accordion|${contentModuleLabel ?? ''}`,
      expanded ? 'Open' : 'Close'
    )
  }

  sendContentModulesMiniCTAData (contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'Mini CTA',
      'Click',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      'Mini CTA',
      this.sharedService.translateLink(contentModuleLabel) ?? ''
    )
  }

  getDocumentTypes (): void {
    this.vehicleDocumentsService.getDocumentTypes().subscribe(types => {
      this.documentTypes = types.filter(type => type?.multiple)

      this.route.params.subscribe(params => {
        if (params.documentType != null) {
          if (params.documentType === 'leasing') {
            const leasing = [
              this.documentTypes.find(type => type.value === 'LEASING_CONTRACT')
            ]
            setTimeout(() => {
              this.documentSelectFormGroup.get('documentType').setValue(leasing)
            })
            this.onDocumentTypeChange(['LEASING_CONTRACT'])
          }
          if (params.documentType === 'warranty') {
            const warranty = [
              this.documentTypes.find(type => type.value === 'WARRANTY')
            ]
            setTimeout(() => {
              this.documentSelectFormGroup
                .get('documentType')
                .setValue(warranty)
            })
            this.onDocumentTypeChange(['WARRANTY'])
          }
        }
        this.documentSelectFormGroup.get('documentType').setValue([])
      })
    })
  }

  onPageChange ($event?): void {
    // this.showPaginator = false

    let result: BasicDocumentDTOExtended[]
    this.documentIndex = undefined
    let pageIndex = 0
    let pageSize = this.pageSize

    if ($event != null) {
      pageIndex = $event.pageIndex
      pageSize = $event.pageSize
    }

    if (this.selectedDocumentType?.length > 0) {
      result = this.allVehicleDocuments.filter(d => {
        let type = d?.type
        if (d?.type === 'SALES_CONTRACT') {
          type = 'CONTRACT'
        }
        if (d?.type === 'SALES_OFFER') {
          type = 'OFFER'
        }

        return this.selectedDocumentType.includes(type)
      })
    } else {
      result = this.allVehicleDocuments
    }
    this.allFilteredDocumentsLength = result?.length ?? 0
    this.vehicleDocuments = result.slice(
      pageIndex * pageSize,
      pageIndex * pageSize + pageSize
    )

    setTimeout(() => {
      this.showPaginator = true
    })
  }

  onDocumentTypeChange (types: string[]): void {
    this.selectedDocumentType = types
    this.onPageChange()
  }

  getUserVehicles (): void {
    this.dataService.onUserVehiclesLoaded.subscribe(
      (allVehicles: VehicleDTOExtended[]) => {
        this.loading = false
        if (allVehicles.length > 0) {
          this.userVehicles = allVehicles
        }
      }
    )
  }

  uploadDocument (): void {
    this.sendEventData('upload')
    const dialogRef = this.dialog.open(
      RevolutionVehicleDocumentUploadPopupComponent,
      {
        data: {
          vehicle: this.vehicle,
          type: 'document'
        },
        panelClass: 'mat-dialog-cpt'
      }
    )

    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result !== false) {
        this.dataService.getVehicleDocuments(this.vehicle.vin)
      }
    })
  }

  onDeleteDocumentClicked (doc: BasicDocumentDTOExtended): void {
    this.sendEventData('delete', doc)
    this.downloadStarted = true
    const dialogRef = this.dialog.open(
      RevolutionVehicleDocumentDeleteConfirmationPopupComponent,
      {
        data: {
          document: doc,
          vehicle: this.vehicle
        },
        panelClass: 'mat-dialog-cpt'
      }
    )
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result !== false) {
        this.dataService.getVehicleDocuments(this.vehicle.vin)
      }
      this.downloadStarted = false
    })
  }

  onEditDocumentClicked (doc: BasicDocumentDTOExtended): void {
    this.sendEventData('edit', doc)
    const dialogRef = this.dialog.open(
      RevolutionVehicleDocumentEditPopupComponent,
      {
        data: {
          document: doc,
          vehicle: this.vehicle
        },
        panelClass: 'mat-dialog-cpt'
      }
    )
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result !== false) {
        this.dataService.getVehicleDocuments(this.vehicle.vin)
      }
    })
  }

  downloadFile (file: BasicDocumentDTOExtended, preview?: boolean): void {
    this.sendEventData('download', file)
    this.cptGtmService.sendDownloadsGtmData(
      'Download',
      this.vehicle.brand,
      this.vehicle.model,
      file.name
    )
    this.downloadStarted = true
    this.sharedService.showSnackbar(
      'customerPortal.customer-portal.please-wait-loading-data',
      null,
      0
    )
    this.documentsService.getDocument(file.id.toString()).subscribe(
      response => {
        this.sharedService.closeSnackbar()
        this.downloadStarted = false
        const blob: Blob = new Blob([response], {
          type: response.type
        })
        const url = URL.createObjectURL(blob)

        if (preview === true) {
          this.sharedService.openPreviewPopup(url, file.format)
        } else {
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.href = url
          a.download = file.name
          a.target = '_blank'
          a.click()
        }
      },
      () => {
        this.downloadStarted = false
        this.sharedService.showSnackbar(
          'customerPortal.customer-portal.loading-data.error',
          null
        )
      }
    )
  }

  setDocuments (documents, invoices): void {
    this.showPaginator = false
    this.vehicleDocuments = []

    this.documents = documents
    this.invoices = invoices

    const vehicleDocuments = this.documents ?? []
    const vehicleInvoices = (this.invoices ?? []).filter((doc: BasicDocumentDTOExtended) => {
      return doc.vin === this.vehicle.vin
    })
    this.allVehicleDocuments = vehicleDocuments.concat(vehicleInvoices)
    // sort
    this.sharedService.orderArrayByDate(this.allVehicleDocuments, 'dateModified')

    const unreadDocumentsCount = this.vehicleDocumentsService.unreadDocumentsCount(this.allVehicleDocuments)
    this.vehicleDocumentsService.unreadVehicleDocuments(unreadDocumentsCount)

    this.onPageChange()
  }

  documentRead (document: BasicDocumentDTOExtended): void {
    if (!document.read) {
      this.vehicleDocumentsService.documentRead(document.id, document.type).subscribe(
        () => {
          document.read = true

          // find document in all 3 arrays
          this.myDocuments?.forEach(doc => {
            if (document.id === doc.id && document.type === doc.type) {
              doc.read = true
              //  update LS
              void this.localStorage.setMyDocuments(this.myDocuments, true)
              // emit changes
              this.dataService.myDocumentsLoaded(this.myDocuments)
            }
          })

          this.documents?.forEach(doc => {
            if (document.id === doc.id && document.type === doc.type) {
              doc.read = true
              //  update LS
              void this.localStorage.setVehicleDocuments(this.vehicle?.vin, this.documents, true)
            }
          })

          this.invoices?.forEach(doc => {
            if (document.id === doc.id && document.type === doc.type) {
              this.skipViewUpdateForVin = this.vehicle.vin
              doc.read = true
              //  update LS
              void this.localStorage.setInvoices(this.invoices, true)
              // emit changes
              this.dataService.invoicesLoaded(this.invoices)
            }
          })

          // update unread count
          const unreadDocumentsCount = this.vehicleDocumentsService.unreadDocumentsCount(this.allVehicleDocuments)
          this.vehicleDocumentsService.unreadVehicleDocuments(
            unreadDocumentsCount
          )
        },
        error => {
          this.logger.log(error)
        }
      )
    }
  }

  getVehicleByVin (vin: string, allVehicles: VehicleDTOExtended[]): void {
    let vehicle = null
    if (allVehicles != null) {
      allVehicles.forEach(v => {
        if (v.vin === vin) {
          vehicle = v
        }
      })
    }
    return vehicle
  }

  getDocumentTranslationKey (d: BasicDocumentDTOExtended): string {
    const type = this.documentTypes.find(({ value }) => {
      let t = d?.type
      if (t === 'SALES_CONTRACT') {
        t = 'CONTRACT'
      }
      if (t === 'SALES_OFFER') {
        t = 'OFFER'
      }
      return value === t
    })
    return type?.key
  }

  filterOnlyDocument (doc: BasicDocumentDTOExtended): boolean {
    return this.vehicleDocumentsService.filterOnlyDocument(doc)
  }

  filterOnlyDocumentSales (doc: BasicDocumentDTOExtended): boolean {
    return this.vehicleDocumentsService.filterOnlyDocumentSales(doc)
  }

  filterService (doc: BasicDocumentDTOExtended): boolean {
    return this.vehicleDocumentsService.filterService(doc)
  }

  ngOnInit (): void {
    this.getUserVehicles()
    this.getDocumentTypes()

    this.sub.download = this.vehicleDocumentsService.download.subscribe(
      data => {
        this.downloadStarted = data
      }
    )

    this.sub.onMyDocumentsLoaded =
    this.dataService.onMyDocumentsLoaded.subscribe(documents => {
      this.myDocuments = documents
    })

    this.sub.combineDocumentsInvoices = combineLatest([
      this.dataService.onVehicleDocumentsLoaded,
      this.dataService.onInvoicesLoaded
    ]).subscribe(res => {
      if (this.skipViewUpdateForVin === this.vehicle.vin) {
        this.skipViewUpdateForVin = undefined
      } else {
        this.setDocuments(res[0], res[1])
      }
    })

    this.sub.combineLoading = combineLatest([
      this.dataService.loading.documents,
      this.dataService.loading.invoices
    ]).subscribe(res => {
      this.loadingDocuments = res[0] || res[1]
    })

    this.documentSelectFormGroup
      .get('documentType')
      .valueChanges.subscribe((value: DocumentType[]) => {
        this.showPaginator = false
        const type = value.map(v => v.value)

        this.onDocumentTypeChange(type)
      })

    this.sub.onVehicleDetailsLoaded =
      this.dataService.onVehicleDetailsLoaded.subscribe(vehicleDetailed => {
        this.vehicleDetailed = vehicleDetailed
      })

    this.sub.onResize = this.resizeService.onResize.subscribe(size => {
      this.spinnerSize = size === 'tablet' ? 100 : 30
    })

    this.sub.onUnreadVehicleDocuments =
    this.vehicleDocumentsService.onUnreadVehicleDocuments.subscribe(count => {
      this.unread = count
    })
  }

  actionClicked (action: cptMenuAction, file): void {
    switch (action) {
      case 'download':
        this.downloadFile(file)
        break
      case 'edit':
        this.onEditDocumentClicked(file)
        break
      case 'delete':
        this.onDeleteDocumentClicked(file)
        break
    }
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }

  getTranslation (element: BasicDocumentDTOExtended): string {
    if (this.sharedService.stringExists(element.code)) {
      return this.transifexTranslationsService.translate(element.code, {
        _key:
          'customerPortal.customer-portal.service-history.servicecode.' +
          element.code.toLowerCase(),
        _tags: 'customer-portal, notranslate'
      })
    }
    return element.description
  }

  sendEventData (
    eventType: 'upload' | 'edit' | 'download' | 'delete' | 'open',
    doc?: BasicDocumentDTOExtended
  ): void {
    // matomo
    this.matomoIntegrationService.trackEvent(
      'Documents',
      eventType + ' click',
      this.matomoIntegrationService.formatDocumentData(doc)
    )
  }

  ngOnChanges (changes: SimpleChanges): void {
    if (changes?.vehicle?.currentValue?.vin !== changes?.vehicle?.previousValue?.vin) {
      this.skipViewUpdateForVin = undefined
    }
  }

  onMarkAllAsReadClicked (): void {
    this.vehicleDocumentsService.markAllDocumentsAsRead(this.vehicle.vin)
  }
}
