import { ChangeDetectorRef, Component, Input } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table'
import { User } from '@inside-hub-app/customer-portal-b2c-client'
import {
  CptGoogleTagmanagerService,
  EcondaService,
  GTMEventData,
  MatomoIntegrationService,
  TransifexService
} from '@inside-hub-app/customer-portal-shared'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { NGXLogger } from 'ngx-logger'
import { DataService } from '../../../../services/data.service'
import { InstantErrorStateMatcherService } from '../../../../services/instant-error-state-matcher.service'
import { SharedService } from '../../../../services/shared.service'
import { TradeinService } from '../../../../services/tradein.service'
import { VehicleDTOExtended } from '../../../../services/vehicles.service'

@Component({
  selector: 'customer-portal-app-revolution-trade-molecule',
  templateUrl: './trade-molecule.component.html'
})
export class TradeMoleculeComponent {
  matcher = new InstantErrorStateMatcherService()
  user: User
  loadingRecalls: boolean
  public hasTradeInVehicle
  public currencyLocale: string

  tradeInVehicleUrl

  price = 29080
  savingTradeIn: boolean
  tradeInForm = new FormGroup({
    mileage: new FormControl<number | null>(null, [
      Validators.min(1),
      Validators.max(9999999),
      Validators.nullValidator
    ])
  })

  displayedColumns: string[] = ['monthlyRate', 'km', 'evaluation']
  dataSource = new MatTableDataSource([
    {
      monthlyRate: '15. Mai 2022',
      milleage: '35’300 km',
      evaluation: "CHF 25'250.–"
    }
  ])

  componentSub = {
    recalls: null,
    tires: null,
    tireAppointments: null,
    TiresLoaded: null,
    AppintmentLinkLoaded: null,
    onVehicleVinChange: null
  }

  hasLinks = false
  expandPanel: boolean
  constructor (
    private readonly tradeInService: TradeinService,
    public readonly sharedService: SharedService,
    private readonly dataService: DataService,
    private readonly logger: NGXLogger,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly econdaService: EcondaService,
    public transifexService: TransifexService,
    private readonly matomoIntegrationService: MatomoIntegrationService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.hasTradeInVehicle = this.remoteConfigService.get('hasTradeInVehicle')
    this.currencyLocale = this.remoteConfigService.get('currencyLocale')
  
    this.tradeInVehicleUrl = {
      de: this.remoteConfigService.get('tradeInVehicleUrl.de'),
      fr: this.remoteConfigService.get('tradeInVehicleUrl.fr'),
      it: this.remoteConfigService.get('tradeInVehicleUrl.it')
    }

    this.dataService.onUserLoaded.subscribe(user => {
      this.user = user
    })

    this.componentSub.recalls = this.dataService.loading.recalls.subscribe(
      loading => {
        this.loadingRecalls = loading
        // this.checkAllLoaded()
      }
    )

    this.componentSub.onVehicleVinChange =
      this.dataService.onVehicleVinChange.subscribe(() => {
        this.tradeInForm.reset()
      })
  }

  @Input()
  public vehicle: VehicleDTOExtended

  sendContentModulesData (expanded: boolean, contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'Accordion',
      expanded ? 'Open' : 'Close',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      `Accordion|${this.sharedService.translateLink(contentModuleLabel) ?? ''}`,
      expanded ? 'Open' : 'Close'
    )
  }

  submitTradeIn (): void {
    /** GTM 2.7.5 */
    const gtmData: GTMEventData = {
      event: 'gaEvent2',
      event_name: 'service',
      service: {
        service_type: 'Get in touch - Calculate leasing residual value',
        event_action: 'Manage Leasing',
        button_action: 'Manage Leasing',
        event_category: 'Service',
        event_label: 'Calculate leasing residual value'
      }
    }
    this.cptGtmService.send(gtmData)
    this.econdaService.send({
      content: 'Service',
      Target: ['Trade in vehicle', JSON.stringify(this.vehicle), 1, 'd']
    })
    this.matomoIntegrationService.trackEvent(
      'Services',
      'Trade in vehicle',
      this.matomoIntegrationService.formatVehicleData(this.vehicle)
    )

    this.savingTradeIn = true
    const vin = this.vehicle.vin
    const mileage = this.tradeInForm.controls.mileage.value
    const previewWindow = window.open('', '_blank')
    this.sharedService.setHtmlBlankTab(
      previewWindow,
      this.sharedService.translateLink(
        'customerPortal.customer-portal.loading-data.please-wait'
      )
    )

    this.tradeInService.getTradeInData(vin, mileage).subscribe(
      response => {
        this.savingTradeIn = false
        const data = encodeURIComponent(response.data)
        const lang =
          this.user?.language != null
            ? this.user.language.find(s => s.primary).language
            : 'de'
        const url =
          String(this.tradeInVehicleUrl[lang.toLowerCase()]) + '?data=' + data
        this.sharedService.setDataBlankTab(previewWindow, url)
        this.tradeInForm.reset()
      },
      error => {
        this.savingTradeIn = false
        this.sharedService.setDataBlankTab(previewWindow, null)
        this.logger.debug(error)
        this.tradeInForm.reset()
      }
    )
  }
}
