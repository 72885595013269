import {
  Component,
  ElementRef,
  type OnDestroy,
  type OnInit,
  ViewChild
} from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { NGXLogger } from 'ngx-logger'
import { DataService } from '../../../services/data.service'
import { LocalStorageService } from '../../../services/local-storage.service'
import {
  CptGoogleTagmanagerService,
  ResizeService, type SCREEN_SIZE
} from '@inside-hub-app/customer-portal-shared'
import { SharedService } from '../../../services/shared.service'
// import { MAILBOX } from '../../../mock/messages'
import { TranslationService } from '@emilfreydigital/transifex-angular'
import { distinctUntilChanged } from 'rxjs'
import {
  type MailboxNotificationDTO,
  MyMailboxService,
  type NotificationPageDTO,
  type NotificationTypeDTO
} from '../../../services/my-mailbox.service'
import { ConfirmationQuestionPopupComponent } from '../../basic/confirmation-question-popup/confirmation-question-popup.component'
import { CustomCalendarHeaderComponent } from '../../basic/custom-calendar-header/custom-calendar-header.component'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { addMonths, addYears, format } from 'date-fns'

export interface filter {
  translation: string
  key: string
  id: number
}

@Component({
  selector: 'customer-portal-app-revolution-my-mailbox',
  templateUrl: './my-mailbox.component.html'
})
export class RevolutionMyMailboxComponent implements OnInit, OnDestroy {
  @ViewChild('Mailbox') private readonly mailbox: ElementRef
  public screenSize: SCREEN_SIZE
  customHeader = CustomCalendarHeaderComponent

  myMailboxResponse: NotificationPageDTO
  loadingMyMailbox: boolean
  messageIndex
  selectedPage
  messageTypes: NotificationTypeDTO[]
  loadingTypes: boolean
  dateFormat: string

  periods: filter[] = [
    {
      translation: '',
      key: 'customerPortal.customer-portal.my-mailbox.period.month-1',
      id: 1
    },
    {
      translation: '',
      key: 'customerPortal.customer-portal.my-mailbox.period.month-3',
      id: 3
    },
    {
      translation: '',
      key: 'customerPortal.customer-portal.my-mailbox.period.month-6',
      id: 6
    },
    {
      translation: '',
      key: 'customerPortal.customer-portal.my-mailbox.period.year-1',
      id: 12
    }
  ]

  // transifex
  startDateTranslation
  endDateTranslation
  unreadMessagesText

  sortDir = 'DESC'

  messageSelectFormGroup = new FormGroup({
    messageType: new FormControl<string | null>(null),
    messageRead: new FormControl<string | null>(null),
    period: new FormControl<filter | null>(null),
    startDate: new FormControl<string | null>({ disabled: true, value: null }),
    endDate: new FormControl({ disabled: true, value: null })
  })

  sub = {
    onResize: null,
    onLocalStorageDataChanged: null,
    onUserLoaded: null,
    period: null,
    messageType: null,
    onMyMailboxLoaded: null,
    myMailboxLoading: null,
    onTypesLoaded: null,
    typesLoading: null,
    onMessageCountLoaded: null,
    messageRead: null,
    onLanguageChange: null
  }

  showPaginator = true

  showFilterMobile = false

  selectAll = false

  messagesSelected: number[] = []

  toolTipTextMarkAllAsRead: string
  toolTipTextDeleteAll: string
  constructor (
    public resizeService: ResizeService,
    private readonly sharedService: SharedService,
    private readonly logger: NGXLogger,
    private readonly localStorage: LocalStorageService,
    private readonly dialog: MatDialog,
    private readonly dataService: DataService,
    private readonly myMailboxService: MyMailboxService,
    private readonly transifexTranslationsService: TranslationService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.dateFormat = this.remoteConfigService.get('dateFormat.long').toUpperCase()
    this.screenSize = this.resizeService.screenSize
    this.toolTipTextDeleteAll = this.transifexTranslationsService.translate(
      '',
      {
        _key: 'customerPortal.customer-portal.my-mailbox.delete-selected.title',
        _tags: 'customer-portal, 3.9'
      }
    )
    this.toolTipTextMarkAllAsRead = this.transifexTranslationsService.translate(
      '',
      {
        _key: 'customerPortal.customer-portal.my-mailbox.mark-selected-read.title',
        _tags: 'customer-portal, 3.9'
      }
    )
  }

  sendContentModulesData (expanded: boolean, contentModuleLabel: string): void {
    this.cptGtmService.sendContentModulesData(
      'Accordion',
      expanded ? 'Open' : 'Close',
      this.sharedService.translateLink(contentModuleLabel) ?? '',
      `Accordion|${this.sharedService.translateLink(contentModuleLabel) ?? ''}`,
      expanded ? 'Open' : 'Close'
    )
  }

  isMessageSelected (message: MailboxNotificationDTO): boolean {
    return this.messagesSelected.includes(message.id)
  }

  onPanelExpand (index: number, element): void {
    this.messageIndex = index
    if (element.read === false) {
      // change read
      this.myMailboxService.updateMessage(element).subscribe(
        () => {
          // i dont want to reload data
          element.read = true
          this.myMailboxService.getMyMailboxCount()
        },
        error => {
          this.logger.log(error)
        }
      )
    }
  }

  onSelectAllMessages (checked: boolean): void {
    if (checked) {
      this.messagesSelected = []
      this.selectAll = true
      const messages = this.myMailboxResponse
      messages.data.forEach(m => {
        this.messagesSelected.push(m.id)
      })
    } else {
      this.selectAll = false
      this.messagesSelected = []
    }
  }

  onSelectMessage (message: MailboxNotificationDTO, checked: boolean): void {
    if (checked) {
      if (!this.messagesSelected.includes(message.id)) {
        this.messagesSelected.push(message.id)
      }
    } else {
      this.messagesSelected = this.messagesSelected.filter(
        e => e !== message.id
      )
    }
  }

  onPageChange ($event): void {
    const params = this.getParams()
    params.page = Number($event.pageIndex) + 1
    this.myMailboxService.getMyMailboxData(params)

    // scroll to top
    this.sharedService.scrollIntoView(this.mailbox)
  }

  sortByDate (): void {
    if (this.sortDir === 'DESC') {
      this.sortDir = 'ASC'
    } else {
      this.sortDir = 'DESC'
    }
    // get filtered data
    const params = this.getParams()
    this.myMailboxService.getMyMailboxData(params)
  }

  setTranslations (totalUnread): void {
    const length = !Number.isNaN(totalUnread) ? totalUnread : 0
    setTimeout(() => {
      switch (length) {
        case 0:
          this.unreadMessagesText = this.transifexTranslationsService.translate(
            'You have no unread messages',
            {
              _key: 'customerPortal.customer-portal.my-mailbox.unread-messages.0',
              _tags: 'customer-portal, 3.1'
            }
          )
          break
        case 1:
          this.unreadMessagesText = this.transifexTranslationsService.translate(
            'You have an unread message',
            {
              _key: 'customerPortal.customer-portal.my-mailbox.unread-messages.1',
              _tags: 'customer-portal, 3.1'
            }
          )
          break
        default:
          this.unreadMessagesText = this.transifexTranslationsService.translate(
            'You have {unread} unread messages',
            {
              _key: 'customerPortal.customer-portal.my-mailbox.unread-messages',
              _tags: 'customer-portal, 3.1',
              unread: length
            }
          )
          break
      }

      this.startDateTranslation = this.transifexTranslationsService.translate(
        'date from',
        {
          _key: 'customerPortal.customer-portal.my-mailbox.start-date',
          _tags: 'customer-portal, 3.1'
        }
      )
      this.endDateTranslation = this.transifexTranslationsService.translate(
        'date to',
        {
          _key: 'customerPortal.customer-portal.my-mailbox.end-date',
          _tags: 'customer-portal, 3.1'
        }
      )

      this.periods.forEach(period => {
        switch (period.id) {
          case 1:
            period.translation = this.transifexTranslationsService.translate(
              '1 month',
              {
                _key: 'customerPortal.customer-portal.my-mailbox.period.month-1',
                _tags: 'customer-portal, 3.1'
              }
            )
            break
          case 3:
            period.translation = this.transifexTranslationsService.translate(
              '3 months',
              {
                _key: 'customerPortal.customer-portal.my-mailbox.period.month-3',
                _tags: 'customer-portal, 3.1'
              }
            )
            break
          case 6:
            period.translation = this.transifexTranslationsService.translate(
              '6 months',
              {
                _key: 'customerPortal.customer-portal.my-mailbox.period.month-6',
                _tags: 'customer-portal, 3.1'
              }
            )
            break
          case 12:
            period.translation = this.transifexTranslationsService.translate(
              '1 year',
              {
                _key: 'customerPortal.customer-portal.my-mailbox.period.year-1',
                _tags: 'customer-portal, 3.1'
              }
            )
            break
        }
      })
    })
  }

  deleteMessage (message: MailboxNotificationDTO): void {
    const dialogRef = this.dialog.open(ConfirmationQuestionPopupComponent, {
      data: {
        title: 'customerPortal.customer-portal.my-mailbox.delete-message.title',
        text: 'customerPortal.customer-portal.my-mailbox.delete-message.text',
        cancel: 'shared.cancel',
        save: 'customerPortal.customer-portal.my-mailbox.delete-message.title',
        saveButtonColor: 'warn',
        preventCloseBeforeComplete: true
      },
      panelClass: 'mat-dialog-cpt'
    })
    const sub = dialogRef.componentInstance.confirm.subscribe(() => {
      this.myMailboxService.deleteMessage([message.id]).subscribe(
        () => {
          dialogRef.close()
          this.messagesSelected = []
          // refresh data
          const params = this.getParams()
          params.page = this.myMailboxResponse.page
          this.myMailboxService.getMyMailboxData(params)
        },
        () => {
          dialogRef.close()
          this.sharedService.openConfirmationPopup(
            'shared.error',
            'shared.general-error-message'
          )
        }
      )
    })
    dialogRef.afterClosed().subscribe(() => {
      sub.unsubscribe()
    })
  }

  getParams (): Record<string, unknown> {
    const endDate = this.messageSelectFormGroup.get('endDate').value
    const startDate = this.messageSelectFormGroup.get('startDate').value
    let type = this.messageSelectFormGroup.get('messageType').value
    if (type === 'all') {
      type = null
    }
    let read = this.messageSelectFormGroup.get('messageRead').value
    if (read === 'all') {
      read = null
    }
    const variable = {
      read,
      lang: this.sharedService.currentLanguage(),
      page: 1,
      sortDir: this.sortDir,
      sort: 'dateCreated',
      type,
      dateCreatedStart: this.sharedService.dateExists(startDate)
        ? format(new Date(startDate), 'yyyy-MM-dd').toString()
        : null,
      dateCreatedEnd: this.sharedService.dateExists(endDate)
        ? format(new Date(endDate), 'yyyy-MM-dd').toString()
        : null
    }
    return variable
  }

  getData (): void {
    const lang = this.sharedService.currentLanguage()
    this.myMailboxService.getMessageTypes(lang)
    const params = this.getParams()
    this.myMailboxService.getMyMailboxData(params)
  }

  ngOnInit (): void {
    this.sub.onUserLoaded = this.dataService.onUserLoaded.subscribe(user => {
      // get mailbox after user data loaded since we need language
      this.getData()
    })

    this.sub.onTypesLoaded = this.myMailboxService.onTypesLoaded.subscribe(
      (types: NotificationTypeDTO[]) => {
        this.messageTypes = types
      }
    )
    this.sub.typesLoading = this.myMailboxService.loading.types.subscribe(
      loading => {
        this.loadingTypes = loading
      }
    )

    this.sub.onMyMailboxLoaded =
      this.myMailboxService.onMyMailboxLoaded.subscribe(
        (myMailbox: NotificationPageDTO) => {
          this.messageIndex = undefined // reset opened message index
          this.myMailboxResponse = myMailbox
          this.setTranslations(myMailbox?.totalUnread)

          // reset paginator
          this.showPaginator = false
          setTimeout(() => {
            this.showPaginator = true
          })
        }
      )
    this.sub.myMailboxLoading =
      this.myMailboxService.loading.myMailbox.subscribe(loading => {
        this.loadingMyMailbox = loading
      })

    this.sub.onMessageCountLoaded =
      this.myMailboxService.onMessageCountLoaded.subscribe(
        (myMailbox: NotificationPageDTO) => {
          this.setTranslations(myMailbox?.totalUnread)
        }
      )

    this.sub.onLanguageChange = this.dataService.onLanguageChange.subscribe(
      newLanguage => {
        this.getData()
      }
    )

    // FORM values changed
    this.sub.period = this.messageSelectFormGroup.controls.period.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(value => {
        // calculate dates
        let startDate
        let endDate = new Date()
        switch (value.id) {
          case 1:
            startDate = addMonths(new Date(), -1)
            break
          case 3:
            startDate = addMonths(new Date(), -3)
            break
          case 6:
            startDate = addMonths(new Date(), -6)
            break
          case 12:
            startDate = addYears(new Date(), -1)
            break
          default:
            startDate = null
            endDate = null
            break
        }
        this.messageSelectFormGroup.get('endDate').setValue(endDate)
        this.messageSelectFormGroup.get('startDate').setValue(startDate)

        // get filtered data
        const params = this.getParams()
        this.myMailboxService.getMyMailboxData(params)
      })
    this.sub.messageType =
      this.messageSelectFormGroup.controls.messageType.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe(value => {
          // get filtered data
          const params = this.getParams()
          this.myMailboxService.getMyMailboxData(params)
        })
    this.sub.messageRead =
      this.messageSelectFormGroup.controls.messageRead.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe(value => {
          // get filtered data
          const params = this.getParams()
          this.myMailboxService.getMyMailboxData(params)
        })
  }

  onDeleteMultiClicked (): void {
    const dialogRef = this.dialog.open(ConfirmationQuestionPopupComponent, {
      data: {
        title:
          'customerPortal.customer-portal.my-mailbox.delete-selected.title',
        text: 'customerPortal.customer-portal.my-mailbox.delete-selected.text',
        cancel: 'shared.cancel',
        save: 'shared.clear',
        saveButtonColor: 'warn',
        preventCloseBeforeComplete: true
      },
      panelClass: 'mat-dialog-cpt'
    })
    const sub = dialogRef.componentInstance.confirm.subscribe(() => {
      this.myMailboxService.deleteMessage(this.messagesSelected).subscribe(
        () => {
          dialogRef.close()
          this.messagesSelected = []
          // refresh data
          const params = this.getParams()
          params.page = this.myMailboxResponse.page
          this.myMailboxService.getMyMailboxData(params)
          this.myMailboxService.getMyMailboxCount()
        },
        () => {
          dialogRef.close()
          this.sharedService.openConfirmationPopup(
            'shared.error',
            'shared.general-error-message'
          )
        }
      )
    })
    dialogRef.afterClosed().subscribe(() => {
      sub.unsubscribe()
    })
  }

  onMarkAllAsReadClicked (): void {
    const dialogRef = this.dialog.open(ConfirmationQuestionPopupComponent, {
      data: {
        title:
          'customerPortal.customer-portal.my-mailbox.mark-selected-read.title',
        text: 'customerPortal.customer-portal.my-mailbox.mark-selected-read.text',
        cancel: 'shared.cancel',
        save: 'shared.save',
        saveButtonColor: 'warn',
        preventCloseBeforeComplete: true
      },
      panelClass: 'mat-dialog-cpt'
    })
    const sub = dialogRef.componentInstance.confirm.subscribe(() => {
      this.myMailboxService.markMessagesAsRead(this.messagesSelected).subscribe(
        () => {
          this.messagesSelected = []
          dialogRef.close()
          // refresh data
          const params = this.getParams()
          params.page = this.myMailboxResponse.page
          this.myMailboxService.getMyMailboxData(params)
          this.myMailboxService.getMyMailboxCount()
        },
        () => {
          dialogRef.close()
          this.sharedService.openConfirmationPopup(
            'shared.error',
            'shared.general-error-message'
          )
        }
      )
    })
    dialogRef.afterClosed().subscribe(() => {
      sub.unsubscribe()
    })
  }

  messagesAreSelected (): boolean {
    return this.messagesSelected?.length > 0
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }
}
