import { ChangeDetectorRef, Component, type OnDestroy, type OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { type User } from '@inside-hub-app/customer-portal-b2c-client'
import {
  CptGoogleTagmanagerService,
  ResizeService, type SCREEN_SIZE
} from '@inside-hub-app/customer-portal-shared'
import {
  type CustomerPortalConfig,
  type RevolutionLink
} from '@inside-hub-app/customer-portal-config'
import { KeycloakService } from '@emilfreydigital/keycloak-angular'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
import { TranslateService } from '@ngx-translate/core'
import { NGXLogger } from 'ngx-logger'
import { DataService } from '../../../../services/data.service'
import { LanguageService } from '../../../../services/language.service'
import { LocalStorageService } from '../../../../services/local-storage.service'
import { type VehicleDTOExtended } from '../../../../services/vehicles.service'
import { MultilanguagePopupComponent } from '../../../basic/multilanguage-popup/multilanguage-popup.component'
import { SharedService } from '../../../../services/shared.service'

@Component({
  selector: 'customer-portal-app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit, OnDestroy {
  user: User
  vehicles: VehicleDTOExtended[] = []
  vin: string
  loggedIn: boolean
  loading: boolean
  languages: string[]
  initials: string
  primaryLang: string
  show: boolean

  navbarLinks
  currentLanguage: string
  country
  public screenSize: SCREEN_SIZE

  sub = {
    onLangChange: null,
    onUserLoaded: null,
    onUserVehiclesLoaded: null,
    onVehicleVinChange: null,
    onResize: null
  }

  constructor (
    public readonly sharedService: SharedService,
    private readonly dataService: DataService,
    private readonly dialog: MatDialog,
    private readonly languageService: LanguageService,
    private readonly keycloak: KeycloakService,
    private readonly logger: NGXLogger,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly translate: TranslateService,
    private readonly localStorageService: LocalStorageService,
    public resizeService: ResizeService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.screenSize = this.resizeService.screenSize
    this.navbarLinks = this.remoteConfigService.getArrayValues<RevolutionLink[]>(
      'revolution.links.navbar.links'
    )
    this.country = this.remoteConfigService.get('country.code')
  }

  setShow (event): void {
    this.show = event
  }

  getNavbarLink (link: RevolutionLink): string {
    return this.sharedService.getLink(link)
  }

  openLanguageChange (user: User): void {
    const dialogRef = this.dialog.open(MultilanguagePopupComponent, {
      data: user,
      panelClass: 'mat-dialog-cpt'
    })

    dialogRef.afterClosed().subscribe(() => {
      this.load()
    })
  }

  goToLink (url: string): void {
    window.open(url, '_blank')
  }

  ngOnInit (): void {
    this.currentLanguage = this.translate.currentLang

    this.sub.onLangChange = this.translate.onLangChange.subscribe(val => {
      this.currentLanguage = val.lang
    })
    void this.keycloak.isLoggedIn().then(loggedIn => (this.loggedIn = loggedIn))

    this.sub.onUserLoaded = this.dataService.onUserLoaded.subscribe(user => {
      this.user = user
      if (user != null) {
        this.loggedIn = true
      }
    })

    this.sub.onUserVehiclesLoaded =
      this.dataService.onUserVehiclesLoaded.subscribe(
        data => {
          this.vehicles = data
          if (this.vin == null && data?.[0] != null) {
            this.vin = data[0].vin
          }
        },
        err => {
          if (err.status === 404) {
            this.vehicles = []
          }
        }
      )
    this.sub.onVehicleVinChange = this.dataService.onVehicleVinChange.subscribe(
      vin => {
        this.vin = vin
      }
    )

    this.sub.onResize = this.resizeService.onResize.subscribe(size => {
      this.screenSize = size
      this.cdRef.detectChanges()
    })
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }

  sendGTMNavigationData (label?: string, linkType?: string): void {
    this.cptGtmService.sendNavigationLinkData(
      null,
      'Main Navigation',
      this.sharedService.translateLink(label),
      linkType
    )
  }

  load (): void {
    this.languageService.getLanguages().subscribe(value => {
      this.languages = value
    })
    this.loading = true
    this.dataService.onUserLoaded.subscribe(user => {
      this.user = null
      if (user != null) {
        this.user = user
        this.loading = false
        this.primaryLang =
          this.user.language != null
            ? this.user.language.find(s => s.primary).language
            : 'de'
        this.localStorageService.setLang(this.primaryLang.toLowerCase())
        this.dataService.initialLanguageSetup(this.primaryLang)
      }
    })
  }
}
