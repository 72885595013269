<div class="container py-14 px-4">
  <h1 class="head-title">
    <T
      str="My Mailbox"
      key="customerPortal.customer-portal.my-mailbox"
      tags="customer-portal, 3.1"
    ></T>
  </h1>

  <div #Mailbox></div>

  <div class="w-full mt-4" *ngIf="loadingMyMailbox">
    <ngx-skeleton-loader
      appearance="line"
      animation="progress"
      [count]="12"
    ></ngx-skeleton-loader>
  </div>

  <div
    class="flex flex-col-reverse lg:flex-row md:gap-6"
    *ngIf="!loadingMyMailbox"
  >
    <div class="w-full lg:w-3/4">
      <ng-container
        *ngIf="!myMailboxResponse || myMailboxResponse?.totalItems === 0"
      >
        <div
          class="mt-8 p-5 md:mt-14 w-full h-[calc(100%_-_56px)] bg-mat-ef-revolution-grey-200 rounded-xl flex flex-col justify-center items-center"
        >
          <div class="text-mat-ef-revolution-supergrey-900">
            <mat-icon svgIcon="speaker_notes_off"></mat-icon>
          </div>
          <div class="text-base font-efSans-primary">
            <T
              str="You currently have no messages"
              key="customerPortal.customer-portal.my-mailbox.no-messages"
              tags="customer-portal, 3.1"
            ></T>
          </div>
        </div>
      </ng-container>

      <ng-container
        *ngIf="myMailboxResponse && myMailboxResponse?.totalItems !== 0"
      >
        <div class="mt-4 md:mt-14 md:p-0">
          <!-- Select all, mark as read / delete all section -->
          <div
            class="w-full my-5 flex flex-row gap-2 justify-center md:justify-end items-center"
          >
            <button
              class="revolution max-h-8 max-w-fit min-h-8 min-w-fit px-2 pb-1"
              mat-button
              [disabled]="!messagesAreSelected()"
              (click)="onDeleteMultiClicked()"
            >
              <mat-icon
                class="w-[26px] h-[26px] !m-0"
                [ngClass]="{
                  'fill-mat-ef-revolution-secondary-500': messagesAreSelected()
                }"
                matTooltip="{{ toolTipTextDeleteAll }}"
                [svgIcon]="'delete'"
              >
              </mat-icon>
            </button>
            <button
              data-cy="my-mailbox-mark-as-read-button"
              class="revolution max-h-8 max-w-fit min-h-8 min-w-fit pr-2 pl-3"
              mat-button
              [disabled]="!messagesAreSelected()"
              (click)="onMarkAllAsReadClicked()"
            >
              <mat-icon
                class="w-[24px] h-[24px] !m-0"
                [ngClass]="{
                  'fill-mat-ef-revolution-secondary-500': messagesAreSelected()
                }"
                matTooltip="{{ toolTipTextMarkAllAsRead }}"
                [svgIcon]="'email-open'"
              ></mat-icon>
            </button>
            <mat-checkbox
              data-cy="my-mailbox-select-all-checkbox"
              color="primary"
              labelPosition="before"
              (change)="onSelectAllMessages($event.checked)"
              [checked]="
                myMailboxResponse?.data.length === messagesSelected.length
              "
            >
              <T
                str="Select all"
                key="customerPortal.customer-portal.my-mailbox.select-all"
                tags="customer-portal, 3.9"
              ></T
            ></mat-checkbox>
          </div>

          <div
            class="w-full flex flex-col md:flex-row justify-between md:gap-2 px-4 md:px-0"
          >
            <div
              class="font-efSans text-lg md:text-xl font-bold text-mat-ef-revolution-primary-500"
            >
              {{ unreadMessagesText }}
            </div>
            <div
              class="font-efSans text-base md:text-lg text-mat-ef-revolution-primary-500"
            >
              {{ myMailboxResponse?.totalFilteredItems || 0 }}
              <T
                str="messages"
                key="customerPortal.customer-portal.my-mailbox.messages"
                tags="customer-portal, 3.1"
              >
              </T>
              <span *ngIf="messagesSelected.length > 0">
                &nbsp;&nbsp;{{ messagesSelected.length }}
                <T
                  str="selected"
                  key="customerPortal.customer-portal.my-mailbox.messages.selected"
                  tags="customer-portal, 3.9"
                >
                </T>
              </span>
            </div>
          </div>

          <div class="w-full md:pr-14">
            <div
              class="w-full mb-1 mt-4 px-6 text-mat-ef-revolution-grey-two-500 text-sm font-bold"
            >
              <div class="flex-1 flex gap-2">
                <div class="w-[50px]"></div>
                <div class="flex-1 grid grid-cols-12 gap-2">
                  <div class="col-span-3 hidden md:block">
                    {{ 'type' | sharedTranslate }}
                  </div>
                  <div class="col-span-7 hidden md:block">
                    <T
                      str="Subject"
                      key="customerPortal.customer-portal.my-mailbox.subject"
                      tags="customer-portal, 3.1"
                    ></T>
                  </div>
                  <div
                    class="col-span-12 justify-end md:justify-start md:col-span-2 mr-0 flex gap-1 cursor-pointer"
                    (click)="sortByDate()"
                  >
                    <div>
                      <T
                        str="Date"
                        key="customerPortal.customer-portal.my-mailbox.date"
                        tags="customer-portal, 3.1"
                      >
                      </T>
                    </div>
                    <mat-icon
                      [svgIcon]="
                        sortDir === 'ASC' ? 'expand_less' : 'expand_more'
                      "
                    ></mat-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <mat-divider></mat-divider>

          <div *ngFor="let element of myMailboxResponse?.data; let i = index">
            <mat-expansion-panel
              [hideToggle]="true"
              class="p-0 noPaddingPanelBody"
              (opened)="onPanelExpand(i, element)"
              (afterExpand)="
                sendContentModulesData(
                  true,
                  'customerPortal.customer-portal.my-mailbox'
                )
              "
              (afterCollapse)="
                sendContentModulesData(
                  false,
                  'customerPortal.customer-portal.my-mailbox'
                )
              "
              [expanded]="messageIndex === i"
            >
              <mat-expansion-panel-header
                class="my-0 md:my-2 px-0 md:px-6"
                [ngClass]="{ 'font-bold': !element.read }"
                [collapsedHeight]="'auto'"
                [expandedHeight]="'auto'"
              >
                <mat-panel-title class="m-0 text-mat-ef-revolution-primary-500">
                  <div class="flex-1 hidden md:flex gap-2">
                    <div
                      class="w-[50px] flex items-center justify-center md:justify-start"
                    >
                      <mat-icon
                        class="text-mat-ef-revolution-primary-500"
                        [svgIcon]="element.read ? 'email-open' : 'email'"
                      ></mat-icon>
                    </div>

                    <div class="flex-1 grid grid-cols-12 gap-2">
                      <div class="col-span-3 flex items-center">
                        <div class="font-efSans text-sm md:text-base">
                          {{ element?.type }}
                        </div>
                      </div>

                      <div class="col-span-7 flex items-center">
                        <div class="font-efSans text-sm md:text-base">
                          {{ element?.title }}
                        </div>
                      </div>

                      <div class="col-span-2 flex items-center">
                        <div class="font-efSans text-sm md:text-base">
                          {{ element?.dateCreated | date: dateFormat }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Mail item - small devices-->
                  <div class="flex-1 flex md:hidden gap-2">
                    <div
                      class="w-[50px] flex items-center justify-center md:justify-start"
                    >
                      <mat-icon
                        class="text-mat-ef-revolution-primary-500"
                        [svgIcon]="element.read ? 'email-open' : 'email'"
                      ></mat-icon>
                    </div>

                    <div class="flex-1 flex flex-col gap-2">
                      <div class="flex gap-4">
                        <div class="flex-1">
                          <div class="font-efSans text-sm md:text-base">
                            {{ element?.type }}
                          </div>
                        </div>

                        <div class="flex">
                          <div class="font-efSans text-sm md:text-base">
                            {{ element?.dateCreated | date: dateFormat }}
                          </div>
                        </div>
                      </div>
                      <div class="flex-1">
                        <div class="font-efSans text-sm md:text-base">
                          {{ element?.title }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <mat-checkbox
                    data-cy="my-mailbox-message-checkbox"
                    color="primary"
                    class="ml-2"
                    (click)="$event.stopPropagation()"
                    (change)="onSelectMessage(element, $event.checked)"
                    [checked]="isMessageSelected(element)"
                  >
                  </mat-checkbox>
                </mat-panel-title>

                <ng-template matExpansionPanelContent>
                  <div class="px-3 pt-0 pb-4 md:px-6">
                    <div
                      class="md:ml-[58px]"
                      *ngIf="messageIndex === i"
                      [innerHTML]="element.text | safeHtml"
                    ></div>
                    <div class="w-full mt-5 flex justify-end">
                      <button
                        mat-button
                        mat-stroked-button
                        class="revolution"
                        [disabled]="loadingMyMailbox"
                        (click)="deleteMessage(element)"
                        color="warn"
                      >
                        <mat-icon svgIcon="delete"></mat-icon>
                        {{ 'clear' | sharedTranslate }}
                      </button>
                    </div>
                  </div>
                </ng-template>
              </mat-expansion-panel-header>
            </mat-expansion-panel>
            <mat-divider></mat-divider>
          </div>
          <mat-paginator
            *ngIf="
              myMailboxResponse?.pageSize &&
              myMailboxResponse?.totalFilteredItems >
                myMailboxResponse?.pageSize &&
              showPaginator
            "
            ef-paginator
            [showTotalPages]="10"
            [length]="myMailboxResponse.totalFilteredItems"
            [pageIndex]="myMailboxResponse.page - 1"
            [pageSize]="myMailboxResponse.pageSize"
            (page)="onPageChange($event)"
          >
          </mat-paginator>
        </div>
      </ng-container>
    </div>

    <div class="w-full lg:w-1/4 flex flex-col mt-14">
      <div class="rounded-md bg-mat-ef-revolution-grey-500">
        <div class="px-6 py-2 md:py-6">
          <div
            class="font-efSans text-[15px] font-bold text-mat-ef-revolution-primary-500 relative"
          >
            <T
              str="Filter messages"
              key="customerPortal.customer-portal.my-mailbox.filter"
              tags="customer-portal, 3.1"
            >
            </T>

            <div
              class="absolute top-0 right-0 md:hidden cursor-pointer flex justify-end text-mat-ef-revolution-primary-500"
            >
              <div (click)="showFilterMobile = !showFilterMobile">
                <mat-icon
                  [svgIcon]="showFilterMobile ? 'close' : 'filter_list'"
                ></mat-icon>
              </div>
            </div>
          </div>

          <div
            class="md:flex mt-3"
            [ngClass]="{
              'flex ': showFilterMobile,
              'hidden ': !showFilterMobile
            }"
          >
            <form [formGroup]="messageSelectFormGroup">
              <mat-form-field appearance="legacy" class="white w-full">
                <mat-label>
                  <T
                    str="message type"
                    key="customerPortal.customer-portal.my-mailbox.message-type"
                    tags="customer-portal, 3.1"
                  ></T>
                </mat-label>

                <mat-select formControlName="messageType">
                  <mat-option [value]="'all'">
                    <T
                      str="all"
                      key="customerPortal.customer-portal.my-mailbox.all"
                      tags="customer-portal, 3.1"
                    ></T>
                  </mat-option>
                  <mat-option *ngFor="let d of messageTypes" [value]="d.value">
                    {{ d?.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="legacy" class="white w-full">
                <mat-label>
                  <T
                    str="message-read"
                    key="customerPortal.customer-portal.my-mailbox.message-read"
                    tags="customer-portal, 3.1"
                  ></T>
                </mat-label>

                <mat-select formControlName="messageRead">
                  <mat-option [value]="'all'">
                    <T
                      str="all"
                      key="customerPortal.customer-portal.my-mailbox.all"
                      tags="customer-portal, 3.1"
                    ></T>
                  </mat-option>
                  <mat-option [value]="true">
                    <T
                      str="read"
                      key="customerPortal.customer-portal.my-mailbox.message-read.read"
                      tags="customer-portal, 3.1"
                    ></T>
                  </mat-option>
                  <mat-option [value]="false">
                    <T
                      str="unread"
                      key="customerPortal.customer-portal.my-mailbox.message-read.unread"
                      tags="customer-portal, 3.1"
                    ></T>
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="legacy" class="white w-full">
                <mat-label>
                  <T
                    str="Period"
                    key="customerPortal.customer-portal.my-mailbox.period"
                    tags="customer-portal, 3.1"
                  >
                  </T>
                </mat-label>

                <mat-select formControlName="period">
                  <mat-option [value]="0">
                    <T
                      str="all"
                      key="customerPortal.customer-portal.my-mailbox.all"
                      tags="customer-portal, 3.1"
                    ></T>
                  </mat-option>
                  <mat-option *ngFor="let d of periods" [value]="d">
                    {{ d.translation }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="legacy" class="white w-full">
                <mat-label> {{ startDateTranslation }}</mat-label>

                <input
                  formControlName="startDate"
                  matInput
                  readonly
                  [matDatepicker]="picker1"
                  datePickerFormat={{dateFormat}}
                  placeholder="{{ startDateTranslation }}"
                />
                <mat-datepicker
                  [calendarHeaderComponent]="customHeader"
                  #picker1
                >
                </mat-datepicker>
              </mat-form-field>

              <mat-form-field appearance="legacy" class="white w-full">
                <mat-label> {{ endDateTranslation }}</mat-label>

                <input
                  formControlName="endDate"
                  matInput
                  readonly
                  [matDatepicker]="picker2"
                  datePickerFormat={{dateFormat}}
                  placeholder="{{ endDateTranslation }}"
                />
                <mat-datepicker
                  [calendarHeaderComponent]="customHeader"
                  #picker2
                >
                </mat-datepicker>
              </mat-form-field>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
