import { animate, group, style, transition, trigger } from '@angular/animations'
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  type OnDestroy,
  type OnInit,
  ViewChild
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar'
import {
  ActivatedRoute,
  type ActivatedRouteSnapshot,
  ActivationEnd,
  Router,
  type UrlSegment
} from '@angular/router'
import {
  type User,
  UserService
} from '@inside-hub-app/customer-portal-b2c-client'
import {
  CptGoogleTagmanagerService,
  ResizeService, type SCREEN_SIZE
} from '@inside-hub-app/customer-portal-shared'
import { type CustomerPortalConfig } from '@inside-hub-app/customer-portal-config'
import { TranslateService } from '@ngx-translate/core'
import { KeycloakService } from '@emilfreydigital/keycloak-angular'
import { NGXLogger } from 'ngx-logger'
import { take } from 'rxjs/operators'
import { type ParsedToken } from '../../../../models/keycloak'
import { SharedService } from '../../../services/shared.service'
import {
  CarmatoService,
  type CarmatoWatchlistDTOExtended
} from '../../../services/carmato.service'
import { DataService } from '../../../services/data.service'
import {
  type HeaderContent,
  type NavigationSubPartial,
  type OfferPartial,
  type TopicsPartial
} from '../../../services/header-footer-service.service'
import { LanguageService } from '../../../services/language.service'
import { LocalStorageService } from '../../../services/local-storage.service'
import {
  type VehicleDTOExtended,
  VehiclesService
} from '../../../services/vehicles.service'
import { EfRemoteConfigurationService } from '@inside-hub-app/ef-remote-config'
export interface BreadCrumb {
  translationKey: string
  route: string
}

@Component({
  selector: 'customer-portal-app-header-v2',
  templateUrl: './header-v2.component.html',
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ height: 0 }),
        animate('0.5s ease-out', style({ height: '*' }))
      ]),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        group([
          animate('0.5s ease-in', style({ height: 0 })),
          animate(
            '0.5s ease-in',
            style({
              opacity: 0
            })
          )
        ])
      ])
    ])
  ]
})
export class HeaderV2Component implements OnInit, OnDestroy {
  @ViewChild('headerNav') headerNav
  country
  public header: HeaderContent
  public navigationSub: NavigationSubPartial
  currency: string
  currencyLocale: string
  efWebsiteUrl
  baseUrl: string
  public topicsFirstMenu: TopicsPartial
  public offerFirstMenu: OfferPartial
  public toggleMenu0 = false
  public toggleMenu2 = false
  public toggleMenu3 = false
  menuOpened: number = null
  marketplaceFavorites: CarmatoWatchlistDTOExtended[] = []
  vehicles: VehicleDTOExtended[] = []
  hasMarketPlace: boolean
  user: User
  public loading = true
  public initials = ''
  public home = ''
  public t = 0
  public env = ''
  public url: string
  languages = []
  primaryLang = ''
  translateLoaded: boolean
  loggedIn: boolean
  token: ParsedToken
  openedMobileMenu = false
  hideBreadcrumbs = false
  breadcrumbRoute: string
  breadcrumbTitles: string[] = []
  clickedOutside = false
  isMenuOpened = false
  mobileMenuV2IsOpen = false
  breadCrumbs: BreadCrumb[] = []
  routeHasVin = false
  parts: UrlSegment[]

  sub = {
    onHeaderFooterDataLoadedSub: null,
    onResize: null,
    onUserVehiclesLoaded: null
  }

  public screenSize: SCREEN_SIZE
  constructor (
    private readonly vehiclesService: VehiclesService,
    private readonly userService: UserService,
    private readonly carmatoService: CarmatoService,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    public dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly snackBar: MatSnackBar,
    private readonly keycloak: KeycloakService,
    private readonly logger: NGXLogger,
    private readonly dataService: DataService,
    private readonly languageService: LanguageService,
    public resizeService: ResizeService,
    private readonly sharedService: SharedService,
    private readonly cptGtmService: CptGoogleTagmanagerService,
    private readonly localStorageService: LocalStorageService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly remoteConfigService: EfRemoteConfigurationService<CustomerPortalConfig>
  ) {
    this.country = this.remoteConfigService.get('country.code')
    this.currency= this.remoteConfigService.get('currency')
    this.currencyLocale = this.remoteConfigService.get('currencyLocale')
    this.efWebsiteUrl = this.remoteConfigService.get('efWebsiteUrl')
    this.hasMarketPlace = this.remoteConfigService.get('hasMarketPlace')

    this.screenSize = this.resizeService.screenSize
    this.translateService.onDefaultLangChange
      .pipe(take(1))
      .subscribe(() => (this.translateLoaded = true))
    this.carmatoService.onWatchListLoaded.subscribe(bookmakrs => {
      this.marketplaceFavorites = bookmakrs
    })
    this.sub.onHeaderFooterDataLoadedSub =
      this.dataService.onHeaderFooterDataLoaded.subscribe(value => {
        if (value != null) {
          this.header = value.structure.navigation.content
          this.navigationSub = value.structure.navigation.content.navigationSub
          this.offerFirstMenu =
            value.structure.navigation.content.navigationMain[0] != null
              ? value.structure.navigation.content.navigationMain[0].dropdown
                .offer
              : null
          this.topicsFirstMenu =
            value.structure.navigation.content.navigationMain[0] != null
              ? value.structure.navigation.content.navigationMain[0].dropdown
                .topics
              : null
        }
      })

    this.sub.onResize = this.resizeService.onResize.subscribe(size => {
      this.screenSize = size
      this.mobileMenuV2IsOpen = false
      this.cdRef.detectChanges()
    })

    this.sub.onUserVehiclesLoaded =
      this.dataService.onUserVehiclesLoaded.subscribe(data => {
        this.vehicles = data
      })

    this.baseUrl =
      this.remoteConfigService.get('efWebsiteUrl') +
      (this.remoteConfigService.get('country.code') === 'ch'
        ? '/' + this.translateService.currentLang
        : '')
  }

  @HostListener('window:scroll', ['$event'])
  getScrollHeight (): void {
    if (window.pageYOffset > 0) {
      this.hideBreadcrumbs = true
      if (this.toggleMenu0) {
        this.menuOpened = 0
        this.toggleMenu0 = !this.toggleMenu0
      } else if (this.toggleMenu2) {
        this.menuOpened = 2
        this.toggleMenu2 = !this.toggleMenu2
      } else if (this.toggleMenu3) {
        this.menuOpened = 3
        this.toggleMenu3 = !this.toggleMenu3
      }
    } else {
      this.hideBreadcrumbs = false
    }
  }

  @HostListener('document:click', ['$event.target'])
  public onClick (target): void {
    const clickedInside = this.headerNav.nativeElement.contains(target)
    if (clickedInside === false) {
      this.clickedOutside = true
      this.isMenuOpened = false
      this.mobileMenuV2IsOpen = false
      this.closeMenuIfOpened(this.menuOpened)
    } else {
      this.clickedOutside = false
      this.checkIfCloseDialog()
    }
  }

  closeMenuIfOpened (menuOpened: number): void {
    if (menuOpened === 0) {
      this.toggleMenu0 = false
    } else if (menuOpened === 2) {
      this.toggleMenu2 = false
    } else if (menuOpened === 3) {
      this.toggleMenu3 = false
    }
    this.menuOpened = null
  }

  closeOpenMenu (): void {
    if (this.menuOpened === 0) {
      this.toggleMenu0 = !this.toggleMenu0
    } else if (this.menuOpened === 2) {
      this.toggleMenu2 = !this.toggleMenu2
    } else if (this.menuOpened === 3) {
      this.toggleMenu3 = !this.toggleMenu3
    }
    // this.toggleMenu()
  }

  checkRouteHasVin (snapshot: ActivatedRouteSnapshot): void {
    this.routeHasVin = this.sharedService.stringExists(snapshot.params.vin)
  }

  hideVinRoute (i: number): boolean {
    const hide = this.routeHasVin && i === 2
    return hide
  }

  isVehiclePath (path: string): boolean {
    let isVehicle = false
    if (
      path === 'details' ||
      path === 'service-history' ||
      path === 'documents' ||
      path === 'dealer' ||
      path === 'vehicles'
    ) {
      isVehicle = true
    }
    return isVehicle
  }

  ngOnInit (): void {
    this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        this.checkRouteHasVin(event.snapshot)

        let fullPath = ''
        this.breadCrumbs = [
          {
            route: fullPath,
            translationKey:
              'customerPortal.customer-portal.header.login-dropdown'
          }
        ]

        this.parts = event.snapshot.url
        const vin: string =
          event.snapshot.url.length > 1 ? event.snapshot.url[1].path : undefined

        this.parts.forEach(p => {
          if (
            this.sharedService.stringExists(vin) &&
            this.parts.length > 1 &&
            this.isVehiclePath(p.path)
          ) {
            if (p.path === 'vehicles') {
              fullPath = '/' + p.path + '/' + vin
            } else {
              fullPath = '/vehicles/' + vin + '/' + p.path
            }
          } else {
            fullPath += '/' + p.path
          }

          if (p.path !== 'summary' && p.path !== '') {
            this.breadCrumbs.push({
              route: fullPath,
              translationKey:
                'customerPortal.customer-portal.navigation.' +
                p.path.toLowerCase()
            })
          }
        })
      }
    })

    this.load()
    this.keycloak
      .isLoggedIn()
      .then(loggedIn => (this.loggedIn = loggedIn))
      .catch(() => {
        // err
      })
    this.token = this.keycloak.getKeycloakInstance().tokenParsed as ParsedToken
  }

  ngOnDestroy (): void {
    this.sharedService.unsubscribe(this.sub)
  }

  openCloseMenu (i?: number): void {
    if (i === undefined) {
      this.toggleMenu2 = false
      this.toggleMenu3 = false
      this.toggleMenu0 = false
      return
    }
    if (i === 0) {
      this.toggleMenu2 = false
      this.toggleMenu3 = false
      this.menuOpened = !this.toggleMenu0 ? 0 : null
      this.toggleMenu0 = !this.toggleMenu0
      this.isMenuOpened = false
    } else if (i === 2) {
      this.toggleMenu0 = false
      this.toggleMenu3 = false
      this.menuOpened = !this.toggleMenu2 ? 2 : null
      this.toggleMenu2 = !this.toggleMenu2
      this.isMenuOpened = false
    } else if (i === 3) {
      this.toggleMenu0 = false
      this.toggleMenu2 = false
      this.menuOpened = !this.toggleMenu3 ? 3 : null
      this.toggleMenu3 = !this.toggleMenu3
      this.isMenuOpened = false
    }
  }

  activeStyle (i: number): string {
    let cssClass = ''
    if (
      (i === 0 && this.toggleMenu0) ||
      (i === 2 && this.toggleMenu2) ||
      (i === 3 && this.toggleMenu3)
    ) {
      cssClass = 'text-mat-ef-primary-500 underline'
    }
    return cssClass
  }

  isLast (i: number, breadcrumbs: BreadCrumb[]): string {
    if (breadcrumbs.length === i + 1) {
      return 'text-mat-ef-primary-500 underline'
    } else {
      return ''
    }
  }

  getRoute (
    i: number,
    breadcrumbTitles: string[],
    breadcrumbRoute: string,
    isLast: string
  ): string {
    if (isLast !== 'text-mat-ef-primary-500 underline') {
      if (breadcrumbTitles.length > 1) {
        return breadcrumbRoute.slice(0, breadcrumbRoute.lastIndexOf('/'))
      }
      return breadcrumbRoute
    }
  }

  checkIfCloseDialog (): Record<string, unknown> {
    if (this.menuOpened !== null) {
      return {
        menuOpened: true,
        clickedOutside: null
      }
    } else if (this.clickedOutside) {
      return {
        menuOpened: null,
        clickedOutside: true
      }
    } else {
      return {
        menuOpened: false,
        clickedOutside: false
      }
    }
  }

  load (): void {
    this.languageService.getLanguages().subscribe(value => {
      this.languages = value
    })
    this.loading = true
    this.dataService.onUserLoaded.subscribe(user => {
      this.user = user
      if (user != null) {
        this.initials =
          (user?.firstName != null ? user.firstName.slice(0, 1) : '') +
          (user?.lastName != null ? user.lastName.slice(0, 1) : '')
        this.loading = false
        this.primaryLang =
          this.user.language != null
            ? this.user.language.find(s => s.primary).language
            : 'de'
        this.localStorageService.setLang(this.primaryLang.toLowerCase())
      }
    })
  }

  login (): void {
    this.keycloak
      .login()
      .then(loggedIn => {
        this.logger.info('logged in', loggedIn)
      })
      .catch(error => {
        this.logger.error(error)
      })
  }

  toggleMenu (): void {
    this.closeMenuIfOpened(this.menuOpened)
    this.isMenuOpened = !this.isMenuOpened
  }

  closeDialog (): void {
    this.mobileMenuV2IsOpen = false
  }

  getHref (page): string {
    // if link starts with http dont att prefix
    return (
      (page.content.link.url.startsWith('http') === true ? '' : this.baseUrl) +
      String(page.content.link.url)
    )
  }

  sendGTMNavigationData (label?: string, linkType?: string): void {
    this.cptGtmService.sendNavigationLinkData(
      null,
      'Main Navigation',
      label,
      linkType
    )
  }
}
